import styled, { css } from 'styled-components';

import { darken } from 'polished';

import arrow_left from '../../assets/left-pagination.svg';
import arrow_right from '../../assets/right-pagination.svg';

interface FilterStatusCallProps {
  filterSelected?: boolean;
  backgroundHexColor: string;
}
interface paramsButtonsScore {
  buttonFilterScore: string;
  ordenationFilterData: boolean;
}

export const BodyContainer = styled.div`
  //padding: 2rem 2rem;
  padding: 0.2rem;
  width: 98%;

  @media (max-width: 768px) {
    padding: 2rem 0;
  }
`;

export const FiltersContainer = styled.div`
  width: 100%;
  display: flex;
  height: 5rem;
  padding: 0 0.2rem;
  //height: 7rem;
  align-items: flex-start;
  justify-content: space-between;
  //margin-top: 3rem;
  //margin-bottom: 2rem;

  @media (max-width: 1600px) {
    //margin-bottom: 5rem;
    //margin-top: 3rem;
  }

  @media (max-width: 1000px) {
    height: auto;
  }

  @media (max-width: 768px) {
    flex-direction: column;
    align-items: flex-start;
    margin-bottom: 2rem;
  }
`;

export const FiltersNavigation = styled.div`
  height: 6rem;
  width: 100%;
  //margin-top: 0.5rem;

  nav {
    display: flex;
    justify-content: space-between;
  }

  @media (max-width: 1024px) {
    nav {
    }
  }

  @media (max-width: 768px) {
    height: auto;
    width: auto;

    nav {
      flex-wrap: wrap;
      justify-content: flex-start;
    }
  }
`;

export const FilterStatusCall = styled.a<FilterStatusCallProps>`
  margin-top: 0.5rem;
  text-decoration: none;
  padding-bottom: 0.5rem;
  margin-right: 1rem;

  :hover {
    text-decoration: none;
  }

  span {
    display: flex;

    label {
      color: #80807f;
      margin-left: 1rem;
      font-size: 1.5rem;

      :hover {
        cursor: pointer;

        ${(props) =>
          props.backgroundHexColor &&
          css`
            color: ${darken(0.1, props.backgroundHexColor)};
          `}
      }
    }

    div {
      display: flex;
      margin-top: -0.3rem;
      margin-left: 1rem;
      color: #fff;
      height: 3rem;
      width: 3rem;
      border-radius: 50%;
      align-items: center;
      justify-content: center;

      ${(props) =>
        props.backgroundHexColor &&
        css`
          background-color: ${props.backgroundHexColor};
        `}

      span {
        font-size: 1rem;
      }
    }
  }

  ${(props) =>
    props.filterSelected &&
    css`
      span {
        label {
          color: ${darken(0.1, props.backgroundHexColor)};
          font-weight: 700;
        }

        div {
          color: #fff;
          transform: scale(1.3);

          span {
            transform: scale(1.1);
            font-weight: 600;
          }
        }
      }

      @media (max-width: 768px) {
        padding-bottom: 0;
      }
    `}

  @media(max-width: 1366px) {
    span {
      label {
        font-size: 1.2rem;
      }

      div {
        margin-left: 0.8rem;
      }
    }
  }

  @media (max-width: 768px) {
    border-bottom: 0;
  }
`;

export const FilterStatusCold = styled.a<FilterStatusCallProps>`
  text-decoration: none;
  background-color: #fff;
  display: flex;
  height: 4rem;
  margin-right: 1rem;
  margin-top: 0.5rem;
  align-items: center;
  justify-content: center;
  border-radius: 0.4rem;
  padding: 1.5rem;

  :hover {
    text-decoration: none;
  }

  span {
    display: flex;
    align-items: center;

    label {
      color: #80807f;
      font-size: 1.2rem;

      :hover {
        opacity: 0.6;
        cursor: pointer;
      }
    }

    div {
      display: flex;
      margin-left: 1rem;
      color: #c2c2c1;
      height: 3rem;
      width: 3rem;
      border-radius: 50%;
      align-items: center;
      justify-content: center;

      span {
        font-size: 1.3rem;
      }
    }
  }

  @media (max-width: 1600px) {
    span {
      div {
        span {
        }
      }
    }
  }

  ${(props) =>
    props.filterSelected &&
    css`
      span {
        label {
          font-weight: 600;
        }

        div {
          span {
            color: #1d1d1b;
            font-weight: 600;
          }
        }
      }

      @media (max-width: 768px) {
        padding-bottom: 0;
      }
    `}

  @media(max-width: 1360px) {
    span {
      label {
        margin-left: 0.3rem;
        font-size: 1.2rem;
      }

      div {
        margin-left: 0.8rem;
      }
    }
  }

  @media (max-width: 768px) {
    border-bottom: 0;
  }
`;

export const FiltersInUse = styled.div`
  display: flex;
  width: 62rem;
  justify-content: space-between;

  @media (max-width: 1600px) {
    width: 51rem;
  }

  @media (max-width: 1360px) {
    width: 37rem;
  }

  @media (max-width: 768px) {
    flex-direction: column;
    margin-top: 2rem;
    width: auto;
  }
`;

export const Filter = styled.div`
  display: flex;
  border: 0.1rem solid #c2c2c1;
  border-radius: 0.4rem;
  height: 5rem;
  align-items: center;
  justify-content: space-around;
  width: 20rem;

  span {
    padding-right: 2rem;
    font-size: 1.2rem;
    color: #80807f;
  }

  button {
    border: 0;
  }

  .filter-close {
    color: #c2c2c1;
    background: #e0dfda;
  }

  @media (max-width: 1600px) {
    width: 17rem;
    padding-right: 0.1rem;
    padding-left: 0.3rem;
    margin-left: 1rem;
  }

  @media (max-width: 1360px) {
    width: auto;
    padding-right: 0.1rem;
    padding-left: 0.3rem;
  }

  @media (max-width: 768px) {
    width: auto;
    margin-left: 0;
    margin-top: 1rem;
  }
`;

interface FiltersModalProps {
  is_loading: boolean;
}

export const FiltersModal = styled.button<FiltersModalProps>`
  border: 0;
  height: 5rem;
  width: 5rem;
  border-radius: 50%;
  margin-left: 0.5rem;

  display: flex;
  align-items: center;
  justify-content: center;

  background: rgba(255, 255, 255, 0.7);

  .filter-icon {
    color: #d3d3d2;
  }

  :hover {
    opacity: 0.9;
  }

  ${(props) =>
    props.is_loading &&
    css`
      opacity: 1;
      cursor: initial;
      background: rgba(255, 255, 255, 0.4);
    `}

  @media (max-width: 1360px) {
    height: 4.5rem;
    width: 4.5rem;
    margin-left: 0.5rem;
    margin-right: 0.5rem;
  }

  @media (max-width: 768px) {
    margin: 1rem 0;
    height: 3rem;
    width: 3rem;
  }
`;

export const FiltersByscore = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 5rem;
  width: 20rem;
  background-color: #fff;
  border-radius: 0.4rem;

  span {
    font-size: 1.2rem;
    margin-right: 1rem;
  }

  .all-scores {
    padding-left: 1rem;
  }

  @media (max-width: 1440px) {
    width: 16rem;
  }

  @media (max-width: 768px) {
    margin: 1rem 0;
  }
`;

export const ScoreRate = styled.span`
  font-weight: 600;
`;

export const ScoreRateColor = styled.div`
  margin-right: 0.2rem;
  height: 1rem;
  width: 1rem;
  border-radius: 50%;
  background-color: #19cb9e;
`;

export const ScoreRedColor = styled.div`
  margin-right: 0.2rem;
  height: 1rem;
  width: 1rem;
  border-radius: 50%;
  background-color: #d50f30;
`;

export const ScoreRateValue = styled.span`
  font-weight: 800;
`;

export const ClientsContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  padding-top: 2rem;
`;

export const ClientsGrid = styled.div`
  display: grid;
  width: 100%;
  grid-row-gap: 3rem;
  grid-column-gap: 3rem;
  grid-template-columns: repeat(4, minmax(15rem, 1fr));

  @media (min-width: 2000px) {
    grid-template-columns: repeat(5, minmax(15rem, 1fr));
  }

  @media (min-width: 1600px) {
    grid-template-columns: repeat(4, minmax(13rem, 0.9fr));
  }

  @media (max-width: 1450px) {
    grid-template-columns: repeat(3, minmax(13rem, 0.9fr));
  }

  @media (max-width: 1100px) {
    grid-template-columns: repeat(2, minmax(12rem, 0.9fr));
  }

  @media (max-width: 768px) {
    grid-template-columns: repeat(1, minmax(10rem, 0.9fr));

    justify-content: center;
  }
`;

export const Pagination = styled.div`
  margin-top: 10rem;

  .pagination {
    display: flex;
    justify-content: center;
    align-items: center;

    .previous {
      background: url(${arrow_left}) no-repeat;
    }

    .next {
      background: url(${arrow_right}) no-repeat;
    }

    .next,
    .previous {
      background-position: center 1rem;
      padding: 1rem;

      a {
        display: block;
        height: 5rem;
        width: 5rem;
      }
    }

    li {
      width: 5rem;
      height: 5rem;
      padding: 0.5rem;
      text-align: center;
      margin-right: 1rem;
      font-size: 1.4rem;
      display: flex;
      align-items: center;
      justify-content: center;

      &.active {
        background: #ffffff;
        border-radius: 50%;
        font-weight: 700;
      }

      a {
        color: #454545;
        display: block;
      }
    }
  }

  @media (max-width: 1360px) {
    margin-top: 3rem;

    .pagination {
      .next,
      .previous {
        background-position: center 0.7rem;
        padding: 0.7rem;

        a {
          height: 3rem;
          width: 3em;
        }
      }

      li {
        width: 3rem;
        height: 3rem;
        padding: 0.5rem;
        text-align: center;
        margin-right: 0.7rem;
        font-size: 1rem;
      }
    }
  }

  @media (max-width: 1024px) {
    margin-top: 4rem;

    .pagination {
      .next,
      .previous {
        background-position: center 1rem;
        padding: 1rem;

        a {
          height: 4rem;
          width: 4em;
        }
      }

      li {
        width: 4rem;
        height: 4rem;
        padding: 0.7rem;
        text-align: center;
        margin-right: 0.7rem;
        font-size: 1.2rem;
      }
    }
  }

  @media (max-width: 768px) {
    margin-top: 3rem;

    .pagination {
      .next,
      .previous {
        background-position: center 0.7rem;
        padding: 0.7rem;

        a {
          height: 3rem;
          width: 3em;
        }
      }

      li {
        width: 3rem;
        height: 3rem;
        padding: 0.5rem;
        text-align: center;
        margin-right: 0.7rem;
        font-size: 1rem;
      }
    }
  }
`;

export const NoClientDataContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 10rem;

  span {
    font-size: 1.6rem;
  }

  @media (max-width: 768px) {
    span {
      font-size: 1.2rem;
    }
  }
`;

export const LoadingContainer = styled.div`
  margin: 1rem 0;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
`;

// Updates

export const PageStatusCall = styled.a<FilterStatusCallProps>`
  opacity: 0.5;
  color: #212529;
  height: 5rem;
  width: 12.5%;
  transition: 200ms;
  span {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    div {
      display: flex;
      flex: 1;
      flex-direction: row-reverse;
      align-items: center;
      height: 100%;
      width: 100%;
      padding-left: 0.4rem;
      padding-right: 0.4rem;
      label {
        cursor: pointer;
        margin: 0.3rem;
        :last-child {
          font-weight: bold;
          font-size: 1.5rem;
        }
        :first-child {
          flex: 1;
          text-align: center;
          font-size: 1.2rem;
        }
      }
    }

    .new-card {
      position: absolute;
      float: left;
      border-radius: 10px;
      padding: 2px 5px 2px 5px;
    }

    .white {
      background-color: white;
      border: 5px solid white;
      color: red;
    }

    .red {
      background-color: red;
      border: 5px solid red;
      color: white;
    }

    .bar {
      ${(props) =>
        props.backgroundHexColor &&
        css`
          background-color: ${props.backgroundHexColor};
        `}
      height: 0.5rem;
      width: 100%;
    }
  }

  :hover {
    text-decoration: none;
    color: #212529;
  }

  ${(props) =>
    props.filterSelected &&
    css`
      opacity: 1;
      transition: 200ms;
      background-color: ${props.backgroundHexColor};
      color: #fff;
      width: 13%;
      border-radius: 2px 2px 0 0;
      :hover {
        color: #fff;
      }
    `}

  @media(max-width: 1024px) {
    width: 20%;
  }
  @media (max-width: 768px) {
    width: 50%;
  }
`;

export const FilterArea = styled.div<paramsButtonsScore>`
  @media (max-width: 768px) {
    justify-content: flex-start !important;
    .filters {
      display: inline-table !important;
    }
  }
  display: flex;
  width: 100%;
  background-color: #fff;
  justify-content: flex-end;
  padding: 1rem;
  padding-top: 0;
  margin-top: -2rem;
  label {
    font-size: 1.3rem;
    text-align: center;
    width: 100%;
    margin-bottom: 0.5rem;
    color: #2f2f2f;
    font-weight: bold;
  }
  > div {
    max-width: 50%;
    display: flex;
    flex-direction: row;
    .yearSelect {
      position: absolute;
      width: 140px;
      height: 29px;
      left: 68px;
      top: 158px;
      font-family: 'Montserrat';
      font-style: normal;
      font-weight: 700;
      font-size: 24px;
      line-height: 29px;

      color: #1d1d1b;
    }
    .filters {
      border: 1px solid #f3f3f3;
      border-radius: 5px;
      display: flex;
      flex-direction: row;
      align-items: center;
      padding: 0 1rem 0.5rem 0.5rem;
      .clearFilters {
        height: 90%;
        display: flex;
        width: 100%;
        flex-direction: column;
        margin: auto 1rem;
        > span {
          display: flex;
          width: 100%;
          align-items: center;
          justify-content: center;
          > button {
            position: relative;
            transition: 200ms;
            border: none;
            padding: 0.3rem 0.8rem;
            color: #2f2f2f;
            font-weight: bold;
            font-size: 0;
            cursor: pointer;
            border-radius: 2px;
            background: transparent;
            &:hover {
              background: #f3f3f3;
              transition: 200ms;
            }
            svg {
              width: 25px;
              height: 25px;
              transform: scaleX(-1);
            }
          }
        }
      }
      .ordenation {
        height: 90%;
        display: flex;
        flex-direction: column;
        align-items: center;
        margin: auto 1rem;
        > span {
          display: flex;
          align-items: center;
          flex-direction: row;
          height: 100%;
          align-items: center;
          ${(props) =>
            !props.ordenationFilterData &&
            css`
              .asc {
                background-color: #f3f3f3;
                border-radius: 2px;
              }
            `}
          ${(props) =>
            props.ordenationFilterData &&
            css`
              .desc {
                background-color: #f3f3f3;
                border-radius: 2px;
              }
            `}
          button {
            display: inline-flex;
            border: none;
            border-radius: 2px;
            padding: 0.2rem;
            background-color: transparent;
            font-size: 1.16rem;
            color: #2f2f2f;
            min-width: 8rem;
            justify-content: center;
          }
        }
        .filter-up {
          transform: rotateX(180deg);
        }
      }
      .filterScore {
        height: 90%;
        display: flex;
        flex-direction: column;
        align-items: center;
        > span {
          display: flex;
          align-items: center;
          flex-direction: row;
          span {
            position: relative;
            background-color: transparent;
            padding: 0.2rem;
            border-radius: 50%;
            margin: auto 0.5rem;
            border: 1px #fff solid;
            &::before {
              content: attr(data-hover);
              visibility: hidden;
              opacity: 0;
              background-color: rgba(0, 0, 0, 0.5);
              color: #fff;
              text-align: center;
              border-radius: 5px;
              padding: 5px;
              transition: opacity 500ms ease-in-out;
              white-space: nowrap;
              font-size: 1rem;

              position: absolute;
              z-index: 1;
              left: 0;
              top: 110%;
            }
            &:hover::before {
              opacity: 0.8;
              visibility: visible;
            }
            &.high {
              ${(props) =>
                props.buttonFilterScore === 'high' &&
                css`
                  border-color: #19cb9e;
                `}
            }
            &.medium {
              ${(props) =>
                props.buttonFilterScore === 'medium' &&
                css`
                  border-color: #f8ae24;
                `}
            }
            &.low {
              ${(props) =>
                props.buttonFilterScore === 'low' &&
                css`
                  border-color: #d50f30;
                `}
              &.zerado {
                ${(props) =>
                  props.buttonFilterScore === 'zerado' &&
                  css`
                    border-color: #757574;
                  `}
              }
            }
            button {
              border-radius: 50%;
              color: #fff;
              padding: 0.2rem;
              border: none;
              font-size: 1rem;
              height: 35px;
              width: 35px;
              font-weight: bold;
              &.high {
                background-color: #19cb9e;
              }
              &.medium {
                background-color: #f8ae24;
              }
              &.low {
                background-color: #d50f30;
              }
              &.zerado {
                background-color: #757574;
              }
            }
          }
        }
      }
      .buttonOpenModal {
        border: 1px solid #f3f3f3;
        border-radius: 5px;
        width: 7rem;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-left: 1rem;
        button {
          margin-left: 0;
        }
      }
    }
    @media (max-width: 1024px) {
      > div {
        max-width: 90%;
      }
    }
    @media (max-width: 768px) {
      padding: 0;
      > div {
        max-width: 100%;
        flex-direction: column;
        .filters {
          flex-wrap: wrap;
          margin-top: 2rem;
          padding: 0;
          padding-bottom: 0.5rem;
          label {
            margin-top: 1rem;
          }
          .clearFilters {
            display: flex;
            width: 100%;
            height: auto;
            > span {
              display: flex;
              justify-content: center;
            }
          }
          .ordenation {
            width: 100%;
            height: auto;
            > span {
              height: auto;
            }
          }
          .filterScore {
            height: auto;
            width: 100%;
            > span {
              button {
                font-size: 1rem;
                height: 55px;
                width: 55px;
              }
            }
          }
        }
        .buttonOpenModal {
          width: 100%;
          margin-left: 0;
        }
      }
    }
  }
`;
