export function removeSpecialCaracteres(document: string) {
    if (!document || document === '') {
        return "";
    }

    const onlyNumbers = document.replace(/(\.|\/|\-)/g, "");
    return onlyNumbers;
};

export function removeSpecialCaractersFromPhoneNumber(phone_number: string) {
    if (!phone_number || phone_number === '') {
        return "";
    };

    const onlyNumbers = phone_number.replace("+55", "").replace(/\D/g, "");

    return onlyNumbers;
};


export function toTimestamp(strDate) {
    var datum = Date.parse(strDate);
    return datum / 1000;
}

export function cpfValidate(cpf) {
    cpf = cpf.replace(/\D/g, '');
    if (cpf.toString().length != 11 || /^(\d)\1{10}$/.test(cpf)) return false;
    var result = true;
    [9, 10].forEach(function (j) {
        var soma = 0, r;
        cpf.split(/(?=)/).splice(0, j).forEach(function (e, i) {
            soma += parseInt(e) * ((j + 2) - (i + 1));
        });
        r = soma % 11;
        r = (r < 2) ? 0 : 11 - r;
        if (r != cpf.substring(j, j + 1)) result = false;
    });
    return result;
}