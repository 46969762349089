import React, { useState, useEffect } from 'react';

import FormOption from '../FormOption';

import api from '../../../services/emeserver/api';
import authToken from 'services/emeserver/authToken';

import iFacebookPage from '../../../dtos/iFacebookPage';
import { iForm, iFormData } from '../../../dtos/iEnterpriseFacebookForm';

import addIcon from '../../../assets/plus-icon.svg';
import editIcon from '../../../assets/edit-icon.svg';
import saveIcon from '../../../assets/save-icon.svg';
import confirmIcon from '../../../assets/confirm-icon.svg';

import * as S from './styles';

interface iEnterpriseProps {
    enterprise_id: number;
    name: string;
    pages: iFacebookPage[];
    facebook_forms: iForm[];
    refreshEnterpriseOnSave(): void;
}

const EnterpriseItem: React.FC<iEnterpriseProps> = (props) => {
    const [actionIcon, setActionIcon] = useState(addIcon);
    const [actionStatus, setActionStatus] = useState("add");
    const [formOptions, setFormOptions] = useState<iForm[]>([]);
    const [formsDataToSave, setFormsDataToSave] = useState<iFormData[]>([]);

    useEffect(() => {
        if(props.facebook_forms.length === 0) {
            const newFormOption = {
                page_id: "",
                form_id: ""
            };

            setFormOptions([...formOptions, newFormOption]);
        } else {

            setFormOptions(props.facebook_forms);
        }
    }, []);

    const handleSetActionIconAndStatus = () => {
        if(actionStatus === "add" && props.facebook_forms.length > 0) {
            setActionIcon(editIcon);
            setActionStatus("edit");
        }
    };

    const handleClickActionButton = async () => {
        if(actionStatus === "edit") {
            setActionIcon(addIcon);
            setActionStatus("add");
        }

        if(actionStatus === "add") {
            const newFormOption = {
                page_id: "",
                form_id: ""
            };

            setFormOptions([...formOptions, newFormOption]);
        }

        if(actionStatus === "save") {
            if(formsDataToSave.length > 0) {
                try {
                    const formSaveRes = await api.post('enterprises/forms/create', {
                        forms_to_save: formsDataToSave
                    }, {
                        headers: {
                            'Authorization': authToken.token
                        }
                    });
    
                    if(formSaveRes.status === 201) {
                        setTimeout(() => {
                            setActionIcon(confirmIcon);
                            setActionStatus("confirm");
                        }, 500);

                        setFormsDataToSave([]);
                        
                        props.refreshEnterpriseOnSave();

                        setTimeout(() => {
                            setActionIcon(editIcon);
                            setActionStatus("edit");
                        }, 3000);
    
                    }
                } catch(err) {
                    console.log(err);
                }
            }
        }
    };

    const handleParentCallBackUpdateActionStatusToSave = (page_id: string, form_id: string, last_form_id: string) => {
        let formsData = formsDataToSave;

        if(last_form_id !== "") {
            formsData = formsData.filter(form => form.form_id !== last_form_id);
        }
        
        const newFormData = {
            enterprise_id: props.enterprise_id,
            page_id,
            form_id,
            last_form_id: last_form_id !== "" &&  last_form_id !== undefined ? last_form_id : ""
        };

        setFormsDataToSave([...formsData, newFormData]);

        setActionIcon(saveIcon);
        setActionStatus("save");
    };

    const handleParentCallBackUpdateActionStatusAdd = () => {
        setActionIcon(addIcon);
        setActionStatus("add");
    };

    useEffect(() => {
        handleSetActionIconAndStatus();
    }, []);

    return (
        <S.Container action_status={actionStatus}>
            <S.TitleContainer>
                <S.Title action_status={actionStatus}>
                    {props.name}
                </S.Title>
            </S.TitleContainer>

            <S.OptionsContainer>
                {formOptions.length > 0 && formOptions.map((form, index ) => (
                    <FormOption 
                        key={index}
                        page_id={form.page_id}
                        form_id={form.form_id}
                        pages={props.pages}
                        action_status={actionStatus}
                        updateStatusToSave={handleParentCallBackUpdateActionStatusToSave}
                        updateStatusToAdd={handleParentCallBackUpdateActionStatusAdd}
                    />
                ))
                }
                
            </S.OptionsContainer>

            <S.ActionButton 
                elements_number={formOptions.length === 0 ? 1 : formOptions.length} 
                action_status={actionStatus}
                onClick={() => handleClickActionButton()}
            >
                <S.ActionIcon src={actionIcon} alt="Adicionar"/>
            </S.ActionButton>
        </S.Container>
    );
};

export default EnterpriseItem;