import React from 'react';

import * as S from './styles';
interface AlreadyProps {
  agent: string;
}

const AlreadyRegistered: React.FC<AlreadyProps> = (props) => {
  const { agent } = props;
  return (
    <S.Container style={{ textAlign: 'center' }}>
      <S.Title>Lead já está em atendimento</S.Title>
      <S.Title>
        com o corretor(a) <b>{agent}</b>,
      </S.Title>
      <S.Title>não será possivel criar este contato.</S.Title>
    </S.Container>
  );
};

export default AlreadyRegistered;
