import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  width: 100vw;
  height: 100vh;
  align-items: center;
  justify-content: center;
`;

export const Card = styled.div`
  padding: 10px;
  width: 40vw;
  height: 80vh;
  background-color: #fff;

  @media(max-width: 768px) {
    width: 80vw;
  }
`;

export const Form = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  flex-direction: column;
`;

export const InputsContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
`;

export const HeaderContainer = styled.header`
  background: #fff;
  padding: 2rem 4.5rem;
  height: 16rem;
  width: 85%;

  display: flex;
  justify-content: space-between;
  align-items: center;

  @media (max-width: 768px) {
    display: block;
    height: auto;
    padding: 2rem 0.5rem;
  }
`;

export const LogoContainer = styled.div`
  display: flex;

  h1 {
    margin: 0;
    padding: 0;
    font-weight: 300;
    font-size: 3.6rem;
    padding-top: 1.3rem;
    margin-left: 2rem;

    span {
      font-weight: 800;
      font-size: 3.6rem;
    }
  }

  @media (max-width: 768px) {
    justify-content: center;

    h1 {
      margin-left: 1rem;
      font-size: 1.4rem;

      span {
        font-size: 1.4rem;
      }
    }
  }
`;

export const IconContainer = styled.div`
  margin-left: 40%;
  margin-top: 1rem;
  margin-bottom: -1.5rem;

  @media(max-width: 768px) {
    margin-left: 90%;
  }
`;

export const InputContainer = styled.div`
  display: flex;
  align-items: center;

  background-color: #f3f3f3;
  width: 50%;
  height: 5rem;
  padding: 0.5rem;
  border-radius: 0.5rem;
  justify-content: space-between;

  input {
    background-color: #f3f3f3;
    border: 0;
    margin-right: 0.5rem;
    font-size: 1.2rem;
    width: 100%;
    height: 100%;
    padding-left: 0.5rem;

    ::placeholder {
      font-size: 1.2rem;
    }
  }

  .search-icon {
    color: #d5d5d4;
  }

  @media (max-width: 768px) {
    width: 100%;
    justify-content: space-between;
    margin-right: 0;
    font-size: 1rem;

    input {
      ::placeholder {
        font-size: 1rem;
      }
    }
  }
`;

export const ButtonContainer = styled.button`
  width: 16rem;
  margin-bottom: -8rem;
  height: 5rem;
  border-radius: 0.5rem;
  background-color: #d50f30;
  border: 0;

  span {
    font-size: 1.4rem;
    font-weight: 600;
    color: #fff;
  }

  :hover {
    opacity: 0.6;
  }

  @media (max-width: 1024px) {
    margin-bottom: 2rem;
    right: 0;
    left: 4rem;
  }

  @media (max-width: 768px) {
    left: auto;
    right: auto;
    position: initial;
    margin-bottom: 0.5rem;
    width: 50%;

    span {
      font-size: 1.1rem;
    }
  }
`;
