import styled, { css } from 'styled-components';

interface iContainerProps {
    action_status: string;
}

export const Container = styled.div<iContainerProps>`
    display: flex;
    align-items: center;
    justify-content: space-between;
    min-height: 12rem;
    background-color: #FFFFFF;
    border: 1px solid #D3D3D2;
    border-radius: 0.5rem;
    margin-bottom: 3rem;

    ${props => props.action_status === "save" && css`
        border: 1px solid #D50F30;
    `}

    ${props => props.action_status === "confirm" && css`
        border: 1px solid #19CB9E;
    `}
`;

export const TitleContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 25%;
    height: 100%;
    min-height: 12rem;
`;

interface iTitleProps {
    action_status: string;
}

export const Title = styled.h3<iTitleProps>`
    font-size: 1.8rem;
    color: #1D1D1B;
    font-weight: 600;

    ${props => props.action_status === "save" && css`
        color #D50F30;
    `}

    ${props => props.action_status === "confirm" && css`
        color #19CB9E;
    `}
`;

export const OptionsContainer = styled.div`
    width: 75%;
`;

interface iActionProps {
    elements_number: number;
    action_status: string;
}

export const ActionButton = styled.button<iActionProps>`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 4%;
    background-color: #F3F3F3;
    border: 0;
    height: 100%;
    cursor: pointer;

    ${props => props.elements_number && css`
        min-height: calc(12rem * ${props.elements_number});
    `}

    ${props => props.action_status === "edit" && css`
       background-color: #1D1D1B;
    `}

    ${props => props.action_status === "save" && css`
       background-color: #D50F30;
    `}

    ${props => props.action_status === "confirm" && css`
        background-color: #19CB9E;
    `}
   
    border-radius: 0 0.5rem 0.5rem 0;
`;

export const ActionIcon = styled.img``;