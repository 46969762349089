import { createGlobalStyle } from 'styled-components';

export default createGlobalStyle`
    #root {
        font-size: 60.0%;
        background: #e0dfda;
    }
    
    * {
        padding: 0;
        margin: 0;
        box-sizing: border-box;
    }

    body {
        -webkit-font-smoothing: antialiased;

        .tooltip-inner {
            font-size: 1.3rem;
            border-radius: 0.5rem;
            font-weight: 600;
            opacity: 0.8;
        }
    }

    html, body, #root {
        height: 100vh;
    }

    body,
    input,
    button,
    textarea,
    span,
    small,
    label,
    h3,
    p {
        font: 400 1.4rem 'Montserrat', sans-serif;
        margin: 0;
    }

    button {
        cursor: pointer;
    }
    
    @media(min-width: 768px){
        :root {
            font-size: 62.5%;
        }
    }
`;
