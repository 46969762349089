import api from '../../services/emeserver/api';

import authToken from '../../services/emeserver/authToken';

export async function dataItens(id: number, agent_name: number) {
  const response = await api.get(
    `dashboard/client?id=${id}&agent_id=${agent_name}`,
    {
      headers: {
        Authorization: authToken.token,
      },
    },
  );

  return response.data.data;
}

export async function dataEnterprises() {
  const response = await api.get(`enterprises/list/search`, {
    headers: {
      Authorization: authToken.token,
    },
  });

  return response.data.response_data;
}

export async function dataStatusLog(call_id: any) {
  const statusLog = await api.get(`/call/status?call_id=${call_id}`, {
    headers: {
      Authorization: authToken.token,
    },
  });
  return statusLog.data;
}

export async function dataEnterprisesGroup(group_dashboard: string) {
  const response = await api.get(`enterprises/list/searchgroup?group_dashboard=${group_dashboard}`, {
    headers: {
      Authorization: authToken.token,
    },
  });

  return response.data.response_data;
}
