import React, { ReactNode, useState } from "react";
import { Container, DropdownItem, ListItem } from "./styles";

interface Props{
  children?: ReactNode;
  title: string;
  icon?: string;
  show: boolean;
  onChange?: (param: string) => void;
  listItem: {
    title: string, 
    img?: string
  }[];
}

export const Dropdown: React.FC<Props> = ({title, icon, listItem, onChange, show}) => {
  const [showDrop, setShowDrop] = useState(show);
  const [selected, setSelected] = useState(title);
  const handleClick = (value: string) => {
    onChange && onChange(value);
    setSelected(value);
    handleShow();
  }
  const handleShow = () => {
    setShowDrop(!showDrop);
  }
  return(
    <Container>
      <button onClick={handleShow}>
        { icon && ( <img src={icon} /> ) } 
        {selected}
      </button>
      {
        showDrop && (
          <DropdownItem>
            { 
            listItem.map(item => (
              <ListItem key={item.title}>
                { item.img && ( <img src={item.img} /> )}
                <button onClick={() => handleClick(item.title)}>
                  {item.title}
                </button>
              </ListItem>
            ))
            }
          </DropdownItem>
        )
      }
    </Container>
  )
}