export function getInitialsByFullName(full_name: string) {
  if (full_name === undefined || full_name === '') {
    return '';
  }

  const regExp = full_name.match(/\b\w/g) || [];
  const initials = (
    (regExp.shift() || '') + (regExp.pop() || '')
  ).toUpperCase();
  return initials;
}

export function getInitalByFullname(full_name: string) {
  if (full_name === undefined || full_name === '') {
    return '';
  }

  const regExp = full_name.match(/\b\w/g) || [];
  const initial = regExp.shift().toUpperCase();
  return initial;
}

export const capitalizeWords = (text: string): string => {
  return text
    .toLowerCase()
    .split(' ')
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(' ');
};
