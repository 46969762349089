import React, { ReactNode } from 'react';
import {
  Agent,
  CitiesWithEnterprises,
} from 'utils/InterFaces';
import { Modal } from 'components/Modal/ConversationModal';

interface Props {
  children?: ReactNode;
  agents?: Agent[];
  listEnterprise?: CitiesWithEnterprises[];
  handleShowReportModal: () => void;
  showModalReports: boolean;
  actualStatus?: string;
  conversationId?: string;
}

const ConversationModal = (props: Props) => {
  const isShowing = props.showModalReports;
  const toggle = props.handleShowReportModal;
  const status = props.actualStatus

  return (
    <React.Fragment>
      <Modal
        isShown={isShowing}
        hide={toggle}
        actualStatus={status}
        conversationId={props.conversationId}
      />
    </React.Fragment>
  );
};

export default ConversationModal;
