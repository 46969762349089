import React, { FormEvent, useCallback, useEffect, useState } from 'react';
import { ReactSVG } from 'react-svg';
import * as S from '../styles';
import hmLogo from '../../../assets/hm-logo.svg';
import api from '../../../services/emeserver/api';
import authToken from '../../../services/emeserver/authToken';
import { toast, ToastContainer } from 'react-toastify';
import { useHistory } from 'react-router-dom';
import { capitalizeWords } from 'utils/nameTreatment';

const Update = () => {
  const [agent, setAgent] = useState('');
  const [teamList, setTeamList] = useState<{ value: string; label: string }[]>(
    [],
  );
  const history = useHistory();

  const validateFields = () => {
    if (!agent) {
      toast.error('É necessário selecionar um corretor');
      return false;
    }
  };

  const getAllAgents = useCallback(async () => {
    const agentsResponse = await api.get('/agents', {
      headers: {
        Authorization: authToken.token,
      },
    });
    const { agents } = agentsResponse.data;

    return agents;
  }, []);

  useEffect(() => {
    async function setAllAgents() {
      const agents = await getAllAgents();

      const filterValidAgents = agents.filter(
        (agent) => agent.permission === 'corretor' && agent.activated,
      );

      setTeamList(filterValidAgents);
    }

    async function fetch() {
      await setAllAgents();
    }

    fetch();
  }, [getAllAgents]);

  const handleSubmit = async (e: FormEvent) => {
    e.preventDefault();
    console.log(agent);
    const data = { agent: agent };

    try {
      const response = await api.put('agents/update/desactive', data, {
        headers: { Authorization: authToken.token },
      });

      if (
        response.data.statusCode === 400 ||
        response.data.status === 'existent_agent'
      ) {
        return toast.error(response.data.message);
      }

      toast.success('Corretor desativado com sucesso!');
      history.push('/home');
    } catch (error) {
      toast.error('Erro ao desativar o corretor. Tente novamente.');
    }
  };

  return (
    <S.Container>
      <S.Card>
        <S.LogoContainer>
          <ReactSVG src={hmLogo} />
          <h1>
            Dashboard <span>Leads</span>
          </h1>
        </S.LogoContainer>

        <S.Form>
          <S.InputsLabel htmlFor="groupDashboard">Nome</S.InputsLabel>
          <S.InputsContainer>
            <S.InputContainer isEmpty={!agent} style={{ marginBottom: '10px' }}>
              <select
                id="agent"
                name="Agent"
                value={agent}
                onChange={(e) => setAgent(e.target.value)}
                required
                className="fieldStyle"
              >
                <option value="" hidden disabled>
                  Selecione um corretor
                </option>
                {teamList.map((item: any, i: number) => (
                  <option key={i} value={item.id}>
                    {capitalizeWords(item.full_name)}
                  </option>
                ))}
              </select>
            </S.InputContainer>
          </S.InputsContainer>

          <div style={{ marginTop: '4rem' }}>
            <S.ButtonContainer
              onClick={handleSubmit}
              style={{ marginRight: '10px' }}
            >
              <span>Desativar Corretor</span>
            </S.ButtonContainer>

            <S.ButtonContainer
              type="button"
              onClick={() => history.push('/home')}
            >
              <span>Cancelar</span>
            </S.ButtonContainer>
          </div>
        </S.Form>
      </S.Card>
      <ToastContainer />
    </S.Container>
  );
};

export default Update;
