import React, { useState } from 'react';
import { DashboadAgents } from '../../utils/InterFaces';
import {
  Content,
  Table,
  TBody,
  THead,
  CloseButton,
  CloseButtonLabel,
} from './styles';
import { ClientProvider } from '../../context/client';
import RankingDetails from 'components/RankingDetails';
import { Button } from '@material-ui/core';

interface Props {
  reportAgents: DashboadAgents[];
  orderBy: string;
  calls: any;
}

export const TableAgents = (props: Props) => {
  const [isShowModalEnterprise, setIsShowModalEnterprise] = useState(false);
  const [agentInfo, setAgentInfo] = useState({});

  const handleClick = (event) => {
    setIsShowModalEnterprise(true);
    setAgentInfo(event);
  };

  const handleCloseClick = () => {
    setIsShowModalEnterprise(false);
  };

  const orderBy = props.orderBy.toLowerCase();
  return (
    <Content>
      <ClientProvider />
      {isShowModalEnterprise ? (
        <>
          <CloseButton onClick={handleCloseClick}>
            <CloseButtonLabel>Fechar</CloseButtonLabel>
          </CloseButton>
          <RankingDetails calls={props.calls} agent_id={agentInfo} />
        </>
      ) : (
        <Table>
          <THead>
            <tr>
              <th style={{ maxWidth: 90 }}>Ranking</th>
              <th>Corretores</th>
              <th
                style={
                  orderBy.includes('atendimento')
                    ? { background: '#636E72', color: '#fff', maxWidth: 90 }
                    : { maxWidth: 90 }
                }
              >
                Atendimentos
              </th>
              <th
                style={
                  orderBy.includes('vendas')
                    ? { background: '#636E72', color: '#fff' }
                    : {}
                }
              >
                Vendas
              </th>
              <th
                style={
                  orderBy.includes('ratio')
                    ? { background: '#636E72', color: '#fff' }
                    : {}
                }
              >
                Ratio
              </th>
            </tr>
          </THead>
          <TBody>
            {props.reportAgents &&
              props.reportAgents.map((agent: any, index) => {
                return (
                  <tr key={agent.id} onClick={() => handleClick(agent)}>
                    <td align="center">
                      <span className={`ranking rank${index + 1}`}>
                        {index + 1}º
                      </span>
                    </td>
                    <td style={{ color: '#2D3436' }}>
                      <div
                        style={{
                          color: '#007bff',
                          cursor: 'pointer',
                          textDecoration: 'underline',
                        }}
                      >
                        <span className="profile">
                          <span
                            style={{
                              backgroundColor: agent.available
                                ? '#19CB9E'
                                : '#AB220E',
                            }}
                          />
                        </span>
                        {agent.full_name}
                      </div>
                    </td>
                    <td align="center">{agent.quantity}</td>
                    <td align="center">{agent.quantitySold}</td>
                    <td align="center" style={{ color: '#2D3436' }}>
                      {' '}
                      {agent.ratio}%
                    </td>
                  </tr>
                );
              })}
          </TBody>
        </Table>
      )}
    </Content>
  );
};
