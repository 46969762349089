import React, { createContext, useContext, useEffect, useState } from 'react';
import { useQuery } from 'react-query';
import { DashboadAgents, DashboardClients } from '../utils/InterFaces';
import api from '../services/emeserver/api';
import { useAuth } from './auth';
import { setSaturation } from 'polished';

interface IClientProvider {
  clientsData: DashboardClients[];
  callsForRedistribute: number | null;
  agentsNotAdmin: DashboadAgents[];
}

const token =
  'DB20F65F9D282E869E6183503259CD84E12BCF90DC2433AE7AC41DF3A241A364';

const ClientContext = createContext<IClientProvider>({} as IClientProvider);

const ClientProvider: React.FC = ({ children }: any) => {
  const getUserLogged = () => {
    const user = sessionStorage.getItem('selectedAgent');
    const userLogged = user ? JSON.parse(user) : undefined;

    return userLogged;
  };
  const { id, permission, year } = getUserLogged();
  const [clientsData, setClientsData] = useState<DashboardClients[]>([]);
  const [callsForRedistribute, setCallsForRedistribute] = useState(0);
  const [agentsNotAdmin, setAgentsNotAdmin] = useState([]);

  const new_calls_find = async () => {
    if (id && Number(id) > 0) {
      const res = await api.get('/dashboard/new/calls/find', {
        params: {
          agent_id: id,
          agent_permission: permission,
          year: year,
        },
        headers: {
          Authorization: token,
        },
      });

      const clients = res.data.response_data.dashboard_data.clients;
      const callsForRedistribute =
        res.data.response_data.dashboard_data.callsForRedistribute;

      return {
        clients,
        callsForRedistribute,
      };
    } else {
      return {
        clients: [],
        callsForRedistribute: null,
      };
    }
  };

  // const new_calls_find_discarded = async () => {
  //   if (id && Number(id) > 0) {
  //     const res = await api.get('/dashboard/new/calls/discarded', {
  //       params: {
  //         agent_id: id,
  //         agent_permission: permission,
  //       },
  //       headers: {
  //         Authorization: token,
  //       },
  //     });

  //     const clients = res.data.response_data.dashboard_data.clients;
  //     const callsForRedistribute =
  //       res.data.response_data.dashboard_data.callsForRedistribute;

  //     return {
  //       clients,
  //       callsForRedistribute,
  //     };
  //   } else {
  //     return {
  //       clients: [],
  //       callsForRedistribute: null,
  //     };
  //   }
  // };

  useEffect(() => {
    const run = async () => {
      const agentResponse = await api.get('/agents/not-admin', {
        headers: {
          Authorization: token,
        },
      });
      setAgentsNotAdmin(agentResponse.data.agents);
    };
    run();
  }, []);

  useQuery('data', () => new_calls_find(), {
    onSuccess: (result) => {
      setClientsData(result.clients);
      result.callsForRedistribute !== null &&
        setCallsForRedistribute(result.callsForRedistribute.length);
    },
    refetchInterval: 1000 * 60 * 2, // 2 minutes
  });

  return (
    <ClientContext.Provider
      value={{ clientsData, callsForRedistribute, agentsNotAdmin }}
    >
      {children}
    </ClientContext.Provider>
  );
};

const useClientContext = () => {
  const context = useContext(ClientContext);
  return context;
};

export { ClientProvider, useClientContext };
