import React, { useContext, useEffect, useState } from 'react';
import { Field } from 'formik';
import { IMaskInput } from '@mirco312312/react-imask';
import FormContext from 'context/FormContext';
import * as S from './styles';
import AlreadyRegistered from '../AlreadyRegistered';

interface StepOneProps {
  handleFetchRegisteredLead: Function;
  teamList: any;
  alreadyRegistered: boolean;
  agentName: string;
}

const StepOne: React.FC<StepOneProps> = (props) => {
  const { fields, setFields } = useContext(FormContext);

  function changeString(value, property) {
    setFields({ ...fields, [property]: value });
  }

  function getDashboard(e, property) {
    props.handleFetchRegisteredLead();
  }

  function getCpf(e, property) {
    props.handleFetchRegisteredLead();
  }

  return (
    <S.Container>
      {props.alreadyRegistered ? (
        <AlreadyRegistered agent_name={props.agentName} />
      ) : (
        <>
          <select
            name="groupDashboard"
            value={fields.group_dashboard}
            required
            onChange={(e) => changeString(e.target.value, 'group_dashboard')}
            onBlur={(e) => getDashboard(e, 'group_dashboard')}
            className="fieldStyle"
          >
            <option value="" hidden disabled>
              Dashboard
            </option>
            {props.teamList.map((item: any) => (
              <option key={item.value} value={item.value}>
                {item.label}
              </option>
            ))}
          </select>
          <Field>
            {({ field }) => (
              <IMaskInput
                {...field}
                name="cpf"
                placeholder="CPF"
                required
                mask="000.000.000-00"
                value={fields.cpf}
                unmask
                onBlur={(e) => getCpf(e, 'cpf')}
                onAccept={(value) => changeString(value, 'cpf')}
                className="fieldStyle"
              />
            )}
          </Field>

          <Field>
            {({ field }) => (
              <IMaskInput
                {...field}
                name="phone_number"
                placeholder="Telefone"
                required
                mask="(00) 00000-0000"
                value={fields.phone_number}
                unmask
                onAccept={(value) => changeString(value, 'phone_number')}
                className="fieldStyle"
              />
            )}
          </Field>
        </>
      )}
    </S.Container>
  );
};

export default StepOne;
