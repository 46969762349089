import React, { useEffect, useState } from 'react';
import { ReactSVG } from 'react-svg';

import ModalElement from '../../components/Modal';

import {
  dataItens,
  dataEnterprises,
  dataStatusLog,
} from 'components/shared/dataItems';
import {
  Agent,
  CitiesWithEnterprises,
  Data,
  DataEnterprise,
  ICallsLogs,
} from 'utils/InterFaces';
import api from 'services/emeserver/api';
import authToken from 'services/emeserver/authToken';

interface Props {
  id: number;
  score: number;
  color: string;
  nick: string;
  full_name: string;
  callType: string;
  origin: string;
  date: string;
  enterprise: string;
  callId: number;
  last_call_status: string;
  parentCallback: Function;
  agents: Agent[];
  agent_name: string;
  multiples_calls: boolean;
  is_most_recent: boolean;
  automation_waiting?: boolean;
  dateUpdated?: string;
  campaign_participant?: string;
  showModal: boolean;
  setShowModal: Function;
  match: { params: { id: string; agentid: string } };
}

const Card: React.FC<Props> = (props) => {
  const [dataModal, setDataModal] = useState<Data>({});
  const [dataEnterprise, setDataEnterprise] = useState<DataEnterprise[]>([]);
  const [citiesAndEnterpriseName, setCitiesAndEnterpriseName] = useState([]);

  const [agents, setAgents] = useState<Agent[]>();
  const [agentsNotAdmin, setAgentsNotAdmin] = useState<Agent[]>();
  const [agentSession, setAgentSession] = useState<Agent>();
  const [calls, setCalls] = useState([]);
  const [logs, setLogs] = useState<ICallsLogs[]>([]);
  const permission = sessionStorage.getItem('permission');
  const agentLogado = JSON.parse(sessionStorage.getItem('selectedAgent'));

  const handleClose = async () => {
    setDataModal({});
    props.setShowModal(false);
    await props.parentCallback();
  };

  const handleOnHide = () => {
    setDataModal({});
    props.setShowModal(false);
  };

  const getEnterpriseRequest = async () => {
    const { data } = await api.get(
      '/dashboard/cities/list/' + agentLogado.group_dashboard,
      {
        headers: {
          Authorization: authToken.token,
        },
      },
    );

    const citiesData: CitiesWithEnterprises[] = data.response_data;

    return citiesData;
  };

  const findAllAgentsAndSet = async () => {
    const res = await api.get('/agents', {
      headers: {
        Authorization: authToken.token,
      },
    });

    const agent = res.data.agents.find(
      (agent: any) => agent.email === sessionStorage.getItem('user'),
    );

    setAgents(res.data.agents);
    setAgentSession(agent);
  };

  const findAllAgentsNotAdminAndSet = async () => {
    const res = await api.get('/agents/not-admin', {
      headers: {
        Authorization: authToken.token,
      },
    });

    setAgentsNotAdmin(res.data.agents);
  };

  useEffect(() => {
    const openModal = async () => {
      const dataResponseClient: Data = await dataItens(
        Number(props.match.params.id),
        Number(props.match.params.agentid),
      );

      if (dataResponseClient.calls) {
        setCalls(dataResponseClient.calls);
        setDataModal(dataResponseClient);
      }
      const dataResponseEnterprise: DataEnterprise[] = await dataEnterprises();
      const dataResponseStatusLog: ICallsLogs[] = await dataStatusLog(
        dataResponseClient.calls[0].id,
      );
      if (dataResponseStatusLog) {
        setLogs(dataResponseStatusLog);
      }
      const cities = await getEnterpriseRequest();
      await findAllAgentsAndSet();
      await findAllAgentsNotAdminAndSet();
      setCitiesAndEnterpriseName(cities);
      setDataEnterprise(dataResponseEnterprise);
    };
    openModal();
  }, []);

  if (!calls) {
    return null;
  }

  console.log('PROPS', dataModal.agents ? dataModal.agents.full_name : '');

  return (
    <>
      {agents && calls && (
        <ModalElement
          show={true}
          click={handleClose}
          onHide={handleOnHide}
          item={Number(props.match.params.id)}
          agents={agents}
          data={dataModal}
          calls_logs={logs}
          agent_name={dataModal.agents ? dataModal.agents.full_name : ''}
          last_call_status={calls[0] ? calls[0].status : ''}
          last_call_id={calls[0] ? calls[0].id : ''}
          enterpriselist={dataEnterprise}
        />
      )}
    </>
  );
};

export default Card;
