import styled from 'styled-components';

export const Container = styled.div`
  > select {
    padding: 7px 10px;
    border-style: none;
    margin-left: 7px;
    background-color: #f2f2f2;
    color: #2d3436;
    font-family: 'Red Hat text', sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 32px;
    height: 56px;
    width: 120pxpx;
  }
  > select option {
    background-color: #fff;
    font-family: 'Red Hat text', sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 19px;
    color: #2d3436;
  }
  select:focus {
    outline: none;
  }
`;
