import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  position: relative;
  color: #2d3436;
  > button {
    margin-left: 1rem;
    padding: 1rem;
    border-radius: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    outline: none;
    border: none;
    background: transparent;
    flex-direction: row;
    color: #b0bcc1;
  }
  img {
    margin-right: 1rem;
  }
`;

export const DropdownItem = styled.div`
  position: absolute;
  left: 0;
  top: 3rem;
  min-width: 240px;
  display: flex;
  flex-direction: column;
  background: #fff;
  box-shadow: 5px 5px 10px rgba(100, 100, 100, 0.2);
  border-radius: 0.5rem;
  border: 1px solid #dfe6e9;
  border-bottom: none;
  z-index: 100;
`;

export const ListItem = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  border-bottom: 1px solid #dfe6e9;
  padding-left: 1rem;
  transition: 0.2s;
  &:hover {
    background-color: #dfe6e9;
  }
  > button {
    flex: 1;
    padding: 1rem 0.5rem;
    font-size: 1rem;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    outline: none;
    border: none;
    background: transparent;
    height: 100%;
  }
`;
