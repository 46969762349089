  import styled, { css } from 'styled-components';
  import arrowIcon from '../../../assets/arrow-down.svg'

  interface AttendanceProps {
    backgroundHexColor: string;
  }

  interface SalesProps {
    salesTeam: boolean;
  }

  export const BoxModal = styled.div`
    position: fixed;
    overflow: visible;
    height: 800px;
    width: 550px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    padding: 40px 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 20px;
    border-radius: 8px;
    background: #FFF;
    z-index: 1500;
  `;

  export const Header = styled.div<AttendanceProps>`
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: flex-start;
    gap: 8px;

    span {
      color: #757574;
      font-family: Montserrat;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
    }

    .status-div {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      gap: 8px;
      background: #FFF;
    }

    .status-info {
      display: flex;
      width: 235px;
      height: 48px;
      padding: 16px 20px;
      justify-content: center;
      align-items: center;
      border-radius: 4px;
      background: ${(props) => (props.backgroundHexColor ? props.backgroundHexColor : '#fff')};
      span {
        font-weight: 600;
        padding: 0.3rem;
        color: #fff;
      }
    }

    .date-div {
      position: relative;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      border-radius: 8px;
      gap: 8px;
      font-family: Montserrat;
    }

    .select-day {
      width: 235px;
      height: 48px;
      padding: 8px;
      border: 1px solid #757574;
      border-radius: 4px;
      color: #757574;
      font-size: 12px;
      font-weight: 700;
    }
  `;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  padding: 10px;
  gap: 10px;
  text-align: left;
  line-height: normal;
  height: 800px;
  overflow-y: auto;
  overflow-x: hidden;
  
  .date {
    color: #757574;
    font-family: Montserrat;
    font-size: 12px;
    font-weight: 700;
  }

`;

export const Messages = styled.div<SalesProps>`
  margin-bottom: 10px;
  padding-left: 10px;
  border-left: 3px solid ${(props) => (props.salesTeam ? '#CB333B' : '#757574')};

  .date {
    color: #757574;
    font-family: Montserrat;
    font-size: 12px;
    font-weight: 700;
  }

  .author {
    color: ${(props) => (props.salesTeam ? '#CB333B' : '#757574')};
    font-family: Montserrat;
    font-size: 14px;
    font-weight: 700;
  }

  .message {
    color: #000;
    font-family: Montserrat;
    font-size: 14px;
    font-weight: 400;
  }
`

  export const LoadingContainer = styled.div`
    margin: 1rem 0;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
  `;

  export const CloseButton = styled.button`
    display: flex;
    align-items: end;
    font-size: 2rem;
    border: none;
    border-radius: 3px;
    margin-left: auto; 
    margin-right: 2rem;
    margin-bottom: -1rem;
    margin-top: -2rem;
    background: none;
    :hover {
      cursor: pointer;
    }
  `;

  export const Backdrop = styled.div`
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background: rgba(0, 0, 0, 0.3);
    z-index: 1500;
  `;
