import styled, { css } from "styled-components";

export const Container = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 6rem;
    height: 100%;
    min-height: 12rem;
    border-bottom: 1px solid #F3F3F3;
    border-left: 1px solid #F3F3F3
`;

export const Page = styled.div`
    width: 50%;
`;

export const PageLabel = styled.label`
    margin-right: 2rem;
    font-size: 1.6rem;
    font-weight: 600;
    color: #757574;
`;

interface iPageSelectedProps {
    disabled: boolean;
}

export const PageSelect = styled.select<iPageSelectedProps>`
    height: 5.5rem;
    width: 100%;
    max-width: 46rem;
    border-color: #D3D3D2;
    border-radius: 0.5rem;
    font-size: 1.4rem;
    color: #757574;
    padding: 0 2rem;

    ${props => props.disabled && css`
        background-color: #E1E1E1;
    `}
`;

export const Form = styled.div`
    width: 50%;
    margin-left: 1rem;
`;

export const FormLabel = styled.label`
    margin-right: 2rem;
    font-size: 1.6rem;
    font-weight: 600;
    color: #757574;
`;

export const NoFormsLabel = styled.label`
    margin-right: 2rem;
    margin-left: 1rem;
    font-size: 1.3rem;
    font-weight: 500;
    color: #757574;
    text-align: center;
`;

interface iFormSelectedProps {
    disabled: boolean;
}

export const FormSelected = styled.select<iFormSelectedProps>`
    height: 5.5rem;
    width: 100%;
    max-width: 46rem;
    border-color: #D3D3D2;
    border-radius: 0.5rem;
    font-size: 1.4rem;
    color: #757574;
    padding: 0 2rem;

    ${props => props.disabled && css`
        background-color: #E1E1E1;
    `}
`;