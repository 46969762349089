import React, { FormEvent, useState } from 'react';
import { ReactSVG } from 'react-svg';
import { useHistory } from 'react-router-dom';
import { FiEye, FiEyeOff } from 'react-icons/fi';
import 'react-toastify/dist/ReactToastify.css';

import hmLogo from '../../assets/hm-logo.svg';

import api from '../../services/emeserver/api';

import authToken from '../../services/emeserver/authToken';

import * as S from './styles';
import { toast, ToastContainer } from 'react-toastify';

export default function ForgotPassword(): JSX.Element {
  const [step, setStep] = useState(1);
  const [code, setCode] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [showPassword, setShowPassword] = useState(false);

  const history = useHistory();

  async function validateCode(e: FormEvent) {
    e.preventDefault();

    const sessionCode = sessionStorage.getItem('code');

    if (code === sessionCode) {
      return setStep(step + 1);
    }

    return toast.error('Verifique o codigo digitado', {
      position: 'top-right',
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  }

  async function registerNewPassword(e: FormEvent) {
    e.preventDefault();

    const email = sessionStorage.getItem('email');

    if (password !== confirmPassword) {
      return toast.error('As senhas precisam ser iguais', {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }

    const data = {
      email,
      password,
    };

    const response = await api.post('agents/updatepassword', data, {
      headers: { 
        Authorization: authToken.token
      }
    });

    if (response.status === 200) {
      history.push('/');
    }
  }

  return (
    <S.Container>
      <S.Card>
        <S.Form>
          <S.LogoContainer>
            <ReactSVG src={hmLogo} />
            <h1>
              Dashboard <span>Leads</span>
            </h1>
          </S.LogoContainer>

          {step === 1 && (
            <>
              <S.InputsContainer>
                <S.InputContainer>
                  <input
                    type="number"
                    placeholder="Digite o codigo de confirmacao"
                    value={code}
                    onChange={(e) => setCode(e.target.value)}
                  />
                </S.InputContainer>
              </S.InputsContainer>
              <S.ButtonContainer onClick={validateCode}>
                <span>Validar</span>
              </S.ButtonContainer>
            </>
          )}

          {step === 2 && (
            <>
              <S.InputsContainer>
                {showPassword ? (
                  <S.IconContainer>
                    <FiEye
                      onClick={(_e) => setShowPassword(!showPassword)}
                      size={20}
                    />
                  </S.IconContainer>
                ) : (
                  <S.IconContainer>
                    <FiEyeOff
                      onClick={(_e) => setShowPassword(!showPassword)}
                      size={20}
                    />
                  </S.IconContainer>
                )}
                <S.InputContainer style={{ marginTop: '30px' }}>
                  <input
                    type={showPassword ? 'initial' : 'password'}
                    placeholder="Senha"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                  />
                </S.InputContainer>

                <S.InputContainer style={{ marginTop: '30px' }}>
                  <input
                    placeholder="Confirme sua senha"
                    value={confirmPassword}
                    type={showPassword ? 'initial' : 'password'}
                    onChange={(e) => setConfirmPassword(e.target.value)}
                  />
                </S.InputContainer>
              </S.InputsContainer>

              <S.ButtonContainer onClick={registerNewPassword}>
                <span>Cadastrar nova senha</span>
              </S.ButtonContainer>
            </>
          )}
        </S.Form>
      </S.Card>
      <ToastContainer />
    </S.Container>
  );
}
