import styled from 'styled-components';

export const CardStyle = styled.div`
  display: flex;

  &:hover {
    cursor: pointer;
    transform: scale(1.07, 1.07);
  }
`;

export const Score = styled.div`
  background: ${(props) => (props.color ? props.color : '#19cb9e')};
  color: #fff;
  border-top-left-radius: 0.5rem;
  border-bottom-left-radius: 0.5rem;
  padding: 1.7rem 1.7rem;

  p {
    display: block;
    text-align: center;
    font-size: 1.1rem;

    b {
      font-weight: 600;
      margin: 0;
      padding: 0;
    }
  }

  h3 {
    font-size: 2.8rem;
    margin-bottom: 5rem;
    color: #fff;
    font-weight: 700;
    padding: 0;
    text-align: center;
  }

  div {
    left: 0;
    width: 100%;
    text-align: center;

    button {
      border-radius: 50%;
      width: 5rem;
      height: 5rem;
      border: 0;
      box-shadow: none;
      background: rgba(255, 255, 255, 0.25);
      color: #fff;

      &:focus,
      &:active {
        outline: none;
      }
    }
  }

  @media(max-width: 1600px) {
      padding: 1rem 1rem;

      p {
        font-size: 1rem;
        width: 5rem;
      }

      h3 {
        font-size: 2rem;
        margin-bottom: 4rem;
      }

      div {
        button {
          width: 4rem;
          height: 4rem;
        }
      }
  }

  @media(max-width: 768px) {
    padding: 0.5rem 0.5rem;

      p {
        font-size: 0.8rem;
        width: 4rem;
      }

      h3 {
        font-size: 1.5rem;
        margin-bottom: 2.2rem;
      }

      div {
        button {
          width: 3rem;
          height: 3rem;
        }
      }
  }

  @media(max-width: 415px) {
      div {
        button {
          margin-top: 3rem;
        }
      }
  }
`;

export const Body = styled.div`
  background: #fff;
  border-top-right-radius: 0.5rem;
  border-bottom-right-radius: 0.5rem;
  padding: 1.4rem 1.4rem;
  flex: 1;
  display: flex;
  flex-direction: column;

  small {
    display: block;
    font-size: 1.2rem;
    color: #757574;
  }

  h3 {
    font-size: 1.6rem;
    color: #1d1d1b;
    margin-bottom: 1.5rem;
    margin-top: 0.5rem;
    font-weight: 600;
  }

  .groupbutton {
    display: flex;
    width: 100%;
    
    .btn-date,
    .btn-blue,
    .btn-green,
    .btn-orange {
      border-radius: 0.3rem;
      display: flex;
      width: 10rem;
      padding: 1rem;
      align-items: center;
      justify-content: center;
      font-size: 1.1rem;
    }

    .btn-blue {
      color: #0d7dc1;
      margin-right: 1rem;
      background: rgba(70, 190, 229, 0.3);
    }

    .btn-green {
      color: #0dc17c;
      margin-right: 1rem;
      background: rgba(70, 229, 190, 0.3);
      text-transform: capitalize;
    }

    .btn-orange {
      color: #FF924F;
      margin-right: 1rem;
      background: rgba(255, 146, 79, 0.3);
    }

    .btn-date {
      background: #f3f3f3;
      color: #454545;

      span {
        margin-left: 0.5rem;
        padding-top: 0.3rem;
        font-size: 1.1rem;
      }
    }
    .btn-days-update{
      display: flex;
      max-width: 110px;
      align-items: center;
      flex-direction: row;
      padding-left: 1rem;
      padding-right: 1rem;
      background: #f3f3f3;
      margin-left: 1rem;
      border-radius: 0.3rem;
      span{
        font-size: 1rem;
        color: #454545;
        word-wrap: break-word;
      }      
      strong{
          font-size: 1.5rem;
          color: #D32F2F;
      }
    }

    @media (max-width: 768px) {
      flex-direction: column;

      > div{
        margin-bottom: 1rem;
      }
      .btn-days-update{
        max-width: 8rem;
        margin-left: 0;
        padding: 1rem;
        strong{
          font-size: 1rem;
        }
        span{
          font-size: 0.8rem;
        }
      }
    }

  }

  @media(max-width: 1600px) {
    padding: 1.2rem 1.2rem;

    small {
    font-size: 1rem;
    }

    h3 {
      font-size: 1.3rem;
      margin-bottom: 1.2rem;
      margin-top: 0.3rem;
    }

    .groupbutton {
      .btn-date,
      .btn-blue,
      .btn-orange {
        width: 9rem;
        padding: 0.8rem;
        font-size: 1rem;
      }

      .btn-blue {
        margin-right: 0.8rem;
      }

      .btn-orange {
        margin-right: 0.8rem;
      }

      .btn-date {
        span {
          margin-left: 0.3rem;
          padding-top: 0.2rem;
          font-size: 1rem;
        }
      }
    }
  }

  @media(max-width: 768px) {
    padding: 1rem 1rem;

    small {
      font-size: 0.5rem;
    }

    h3 {
      font-size: 1rem;
      margin-bottom: 1rem;
      margin-top: 0.3rem;
    }

    .groupbutton {
      .btn-date,
      .btn-blue,
      .btn-orange {
        width: 8rem;
        padding: 0.8rem;
        font-size: 0.7rem;
      }

      .btn-blue {
        margin-right: 0.8rem;
      }

      .btn-orange {
        margin-right: 0.8rem;
      }

      .btn-date {
        span {
          margin-left: 0.3rem;
          padding-top: 0.2rem;
          font-size: 0.8rem;
        }
      }
    }
  }

  @media(max-width: 440px) {
    
    .groupbutton {
      display: flex;
      flex-direction:column;
    }

    .groupbutton {
      .btn-date,
      .btn-blue,
      .btn-orange {
        width: 8rem;
        font-size: 0.7rem;
      }

      .btn-blue {
        margin-right: 0.8rem;
      }

      .btn-orange {
        margin-right: 0.8rem;
      }

      .btn-date {
        margin-top: 0.8rem;

        span {
          margin-left: 0.3rem;
          padding-top: 0.2rem;
          font-size: 0.8rem;
        }
      }
    }
  }
`;

export const MultipleCalls = styled.div`
  display: flex;
  align-self: flex-end;
  height: 2.2rem;
`;

export const IsMostRecentBox = styled.div`
  display: flex;
  align-items: center;
  align-self: flex-end;
  margin-right: 1rem;
  
  label {
    color: #E04B64;
    font-size: 1.2rem;
  }
`;

export const IsMostRecentBadge = styled.div`
  height: 15px;
  width: 15px;
  border-radius: 50%;
  background-color: #E04B64;
  position: relative;
  margin-left: 0.5rem;
`;

export const Automation_waiting = styled.div`
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: flex-end;
  padding-top: 0.7rem;
  label {
    color: rgb(2,118,178);
    font-size: 1.2rem;
    margin-right: 0.5rem;
  }
  img{
    width: 1.6rem;
    margin-right: 1rem;
  }
`;

export const CampaignParticipant = styled.div`
  display: flex;
  padding-top: 0.5rem;
  padding-right: 1rem;
  
  img{
    width: 2.5rem;
    height: 2.5rem;
  }
`;
