import React, { ReactNode } from 'react';
import {
  Agent,
  CitiesWithEnterprises,
} from 'utils/InterFaces';
import { Modal } from 'components/Modal/ReportModal';

interface Props {
  children?: ReactNode;
  agents: Agent[];
  listEnterprise: CitiesWithEnterprises[];
  handleShowReportModal: () => void;
  showModalReports: boolean;
}

const ReportsModal = (props: Props) => {
  const isShowing = props.showModalReports;
  const toggle = props.handleShowReportModal;

  return (
    <React.Fragment>
      <Modal
        headerText="Relatórios Indique um amigo"
        isShown={isShowing}
        hide={toggle}
      />
    </React.Fragment>
  );
};

export default ReportsModal;
