import React, { FunctionComponent } from 'react';
import ReactDOM from 'react-dom';
import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';

import {
  Wrapper,
  Header,
  StyledModal,
  HeaderText,
  CloseButton,
  Content,
  Backdrop,
} from './modal.style';
import { useState } from 'react';
import { Button, TextField } from '@material-ui/core';
import './style.css';
import api from 'services/emeserver/api';
import authToken from 'services/emeserver/authToken';
import { FiXCircle } from 'react-icons/fi';

export interface ModalProps {
  isShown: boolean;
  hide: () => void;
  headerText: string;
}

const content = (
  <React.Fragment>
    <form></form>
  </React.Fragment>
);

export const exportToCsv = (
  filename: string,
  rows: object[],
  headers?: string[],
): void => {
  if (!rows || !rows.length) {
    return;
  }
  const separator: string = ',';

  const keys: string[] = Object.keys(rows[0]);

  let columHearders: string[];

  if (headers) {
    columHearders = headers;
  } else {
    columHearders = keys;
  }

  const csvContent =
    'sep=,\n' +
    columHearders.join(separator) +
    '\n' +
    rows
      .map((row) => {
        return keys
          .map((k) => {
            let cell = row[k] === null || row[k] === undefined ? '' : row[k];

            cell =
              cell instanceof Date
                ? cell.toLocaleString()
                : cell.toString().replace(/"/g, '""');
            //@ts-ignore
            if (navigator.msSaveBlob) {
              cell = cell.replace(/[^\x00-\x7F]/g, ''); //remove non-ascii characters
            }
            if (cell.search(/("|,|\n)/g) >= 0) {
              cell = `"${cell}"`;
            }
            return cell;
          })
          .join(separator);
      })
      .join('\n');

  const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
  //@ts-ignore
  if (navigator.msSaveBlob) {
    // In case of IE 10+
    //@ts-ignore
    navigator.msSaveBlob(blob, filename);
  } else {
    const link = document.createElement('a');
    if (link.download !== undefined) {
      // Browsers that support HTML5 download attribute
      const url = URL.createObjectURL(blob);
      link.setAttribute('href', url);
      link.setAttribute('download', filename);
      link.style.visibility = 'hidden';
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  }
};

const fileType =
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
const fileExtension = '.xlsx';

const exportXLSX = async (csvData) => {
  const teste = csvData.map((row) => ({
    nome_indicador: row.indicador.nome_indicador,
    cpf_indicador: row.indicador.cpf_indicador,
    num_venda_rm_indicador: row.indicador.num_venda_rm_indicador,
    empreendimento_indicador: row.indicador.empreendimento_indicador,
    bloco_indicador: row.indicador.bloco_indicador,
    unidade_indicador: row.indicador.unidade_indicador,
    empresa_vendas_indicador: row.indicador.empresa_vendas_indicador,
    data_contrato_indicador: row.indicador.data_contrato_indicador,
    data_indicacao: row.indicador.data_indicacao,
    nome_indicado: row.indicado.nome_indicado,
    cpf_indicado: row.indicado.cpf_indicado,
    empreendimento_indicado: row.indicado.empreendimento_indicado,
    bloco_indicado: row.indicado.bloco_indicado,
    unidade_indicado: row.indicado.unidade_indicado,
    empresa_vendas_indicado: row.indicado.empresa_vendas_indicado,
    data_contrato_indicado: row.indicado.data_contrato_indicado,
    num_venda_rm_indicado: row.indicado.num_venda_rm_indicado,
  }));

  console.log(teste);
  const fileName = 'report_indique_amigos';
  const worksheet = XLSX.utils.json_to_sheet(teste);
  const workbook = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(workbook, worksheet, 'Relatório Indique Amigos');
  /* fix headers */
  XLSX.utils.sheet_add_aoa(
    worksheet,
    [
      [
        'NOME DO COMPRADOR (INDICADOR)',
        'CPF',
        'Nº VENDA RM',
        'EMPREENDIMENTO',
        'BLOCO',
        'UNIDADE',
        'EMPRESA DE VENDAS (INDICADOR)',
        'DATA CVC',
        'DATA DA INDICAÇÃO',
        'NOME INDICADO',
        'CPF INDICADO',
        'EMPREENDIMENTO',
        'BLOCO',
        'UNIDADE',
        'EMPRESA DE VENDAS (INDICADO)',
        'DATA ASSINATURA CVC',
        'Nº VENDA RM',
      ],
    ],
    { origin: 'A1' },
  );
  const excelBuffer = XLSX.write(workbook, {
    bookType: 'xlsx',
    type: 'array',
  });
  const data = new Blob([excelBuffer], { type: fileType });
  FileSaver.saveAs(data, fileName + fileExtension);
};

export const Modal: FunctionComponent<ModalProps> = ({
  isShown,
  hide,
  headerText,
}) => {
  const [dtInicial, setDtInicial] = useState('');
  const [dtFinal, setDtFinal] = useState('');
  const [isLoading, setIsLoading] = useState(false)

  const geraIndicadores = async () => {
    if (!dtInicial) {
      alert('Necessário preencher a data de início!');
    } else if (!dtFinal) {
      alert('Necessário preencher a data final!');
    } else {
      try {
        const update = await api
          .get(`/indication/buscaIndicadores/${dtInicial}/${dtFinal}`, {
            headers: { Authorization: authToken.token },
          })
          .then((response) => {
            console.log(response);
            exportToCsv('indicadores.csv', response.data.listIndications);
            setIsLoading(false)
          });
      } catch (error) {
        return error.response;
      }
    }
  };

  const geraIndicados = async () => {
    if (!dtInicial) {
      alert('Necessário preencher a data de início!');
      setIsLoading(false);
    } else if (!dtFinal) {
      alert('Necessário preencher a data final!');
      setIsLoading(false);
    } else {
      try {
        const update = await api
          .get(`/indication/buscaIndicados/${dtInicial}/${dtFinal}`, {
            headers: { Authorization: authToken.token },
          })
          .then((response) => {
            console.log(response);
            exportXLSX(response.data.listIndications);
            setIsLoading(false)
          });
      } catch (error) {
        return error.response;
      }
    }
  };

  const modal = (
    <React.Fragment>
      <Backdrop />
      <Wrapper>
        <StyledModal>
          <Header>
            <HeaderText>{headerText}</HeaderText>
            <CloseButton onClick={hide}>
              <FiXCircle />
            </CloseButton>
          </Header>
          <Content>
            <div className="col-md-12 row">
              <div className="col-md-6 inputCSS">
                <TextField
                  label="Data Incial"
                  id="outlined-size-normal"
                  variant="outlined"
                  type="date"
                  required
                  value={dtInicial}
                  onChange={(e) => setDtInicial(e.target.value)}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </div>
              <div className="col-md-6 inputCSS">
                <TextField
                  label="Data Final"
                  id="outlined-size-normal"
                  variant="outlined"
                  type="date"
                  value={dtFinal}
                  onChange={(e) => setDtFinal(e.target.value)}
                  required
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </div>
              <br />
                <div className="col-md-6">
                  <Button
                    onClick={(e) => {
                      e.preventDefault();
                      geraIndicados()
                      setIsLoading(true)
                    }}
                    disabled={isLoading}
                    className={`btn btn-relatorio ${isLoading ? 'btn-disabled' : ''}`}
                  >
                    Relatórios Indicadores
                  </Button>
                </div>
                <div className="col-md-6">
                  <Button
                    onClick={(e) => {
                      e.preventDefault();
                      geraIndicados()
                      setIsLoading(true)
                    }}
                    disabled={isLoading}
                    className={`btn btn-relatorio ${isLoading ? 'btn-disabled' : ''}`}
                  >
                    Relatórios Indicados
                  </Button>
                </div>        
            </div>
          </Content>
        </StyledModal>
      </Wrapper>
    </React.Fragment>
  );

  return isShown ? ReactDOM.createPortal(modal, document.body) : null;
};

export const useModal = () => {
  const [isShown, setIsShown] = useState<boolean>(false);
  const toggle = () => setIsShown(!isShown);
  return {
    isShown,
    toggle,
  };
};
