import styled from 'styled-components';

export const Container = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;
`;

export const Header = styled.header`
    width: 100%;
    max-width: 1920px;
    background-color: #FFFFFF;

    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 6rem 6rem;
`;

export const LogoContainer = styled.div`
    display: flex;
    align-items: center;
`;

export const Logo = styled.img`
    height: 5rem;
    margin-right: 3rem;
`;

export const HeaderTitle = styled.h2`
    font-size: 3rem;
    color: #1D1D1B;
    margin-top: 2rem;
`;

export const SearchContainer = styled.div`
    display: flex;
    align-items: center;
    position: relative;
    height: 6rem;
    width: 100%;
    max-width: 56rem;
    border-radius: 0.5rem;

    .search-icon {
        color: #d5d5d4;
        position: absolute;
        right: 1rem;
    }
`;

export const SearchInput = styled.input`
    height: 6rem;
    width: 100%;
    max-width: 56rem;
    padding-left: 2rem;
    padding-right: 4rem;
    border-radius: 0.5rem;
    background-color: #F3F3F3;
    
    border: 0;
`;

export const SearchIcon = styled.img`
    height: 1.8rem;
    color: #d5d5d4;

`;

export const Body = styled.div`
    width: 100%;
    height: 100%;
    max-width: 1920px;
    padding: 6rem 6.4rem;
`;

export const NoEnterpriseMessage = styled.span`
    font-size: 1.4rem;
    font-weight: 500;
    text-align: center;
    display: block;
`;