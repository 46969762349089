import styled, { css, keyframes } from 'styled-components';
import { darken } from 'polished';

const appearFromTop = keyframes`
  from {
    opacity: 0;
    transform: translateY(-50px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
`;

export const Container = styled.div`
  width: 100vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  animation: ${appearFromTop} 0.5s;
`;

export const Title = styled.h2`
  display: flex;
  align-self: flex-start;
  margin-left: 2rem;
  font-weight: 700;

  @media (max-width: 768px) {
    align-self: center;
    font-size: 1.6rem;
  }
`;

export const Header = styled.head`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
`;

export const CloseButton = styled.button`
  padding: 1rem 1.5rem;
  border: 0;
  background-color: #c2c2c1;
  border-radius: 4px;

  &:hover {
    background-color: ${darken(0.1, '#c2c2c1')};
  }
`;

export const CloseButtonLabel = styled.label`
  font-size: 1.4rem;
  color: #fff;
  cursor: pointer;
`;

export const AgentsContainer = styled.div`
  padding: 4rem;
  width: 52vw;
  background-color: #fff;
  border-radius: 0.4rem;
  display: flex;
  flex-direction: column;
  align-items: center;

  @media (max-width: 1280px) {
    width: 80vw;
  }
`;

export const Agents = styled.div`
  margin-top: 4rem;
  width: 100%;
  /* display: grid; */
  grid-template-columns: repeat(3, 1fr);
  row-gap: 4rem;
  align-items: center;
  justify-items: center;

  @media (max-width: 1024px) {
    grid-template-columns: repeat(2, 1fr);
  }

  @media (max-width: 768px) {
    display: flex;
    flex-direction: column;
  }
`;

export const Agent = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

interface ScoreProps {
  available: boolean;
  number_of_leads: number;
}

export const Score = styled.span<ScoreProps>`
  font-weight: 700;
  color: #fff;
  background-color: #19cb9e;

  ${(props) =>
    !props.available &&
    css`
      background-color: #bf000b;
    `}

  padding: 1.5rem 2.1rem;

  ${(props) =>
    props.number_of_leads > 9 &&
    css`
      padding: 1.5rem 2.1rem;
    `}

  ${(props) =>
    props.number_of_leads > 99 &&
    css`
      padding: 1.5rem 2.1rem;
    `}

    ${(props) =>
    props.number_of_leads > 999 &&
    css`
      padding: 1.5rem 1rem;
    `}

    border-radius: 50%;

  @media (max-width: 768px) {
    padding: 1rem 0.5rem;
    font-size: 1.2rem;
  }
`;

export const Name = styled.span`
  font-weight: 600;
  font-size: 1.4rem;
  margin-left: 1.5rem;
  width: 70%;
  position: relative;

  button {
    padding: 1rem 0.5rem;
    border: none;
    background-color: transparent;
  }

  &:hover {
    span {
      display: block;
      transition: opacity 0.5s;
    }
  }

  span {
    display: none;
    overflow: hidden;
    position: absolute;
    top: 5rem;
    left: 2rem;
    padding: 0.5rem 1rem;
    border-radius: 5px;
    background: #212529;
    color: #fff;
    opacity: 0.7;
    z-index: 100;
    transition: opacity 0.5s;
  }

  @media (max-width: 768px) {
    padding: 1rem 0.5rem;
    font-size: 1.2rem;
  }
`;

export const TotalClients = styled.label`
  display: flex;
  align-self: flex-start;
  margin-top: 2rem;
  margin-left: 2rem;
  font-size: 1.6rem;

  span {
    font-weight: 600;
    margin-left: 1rem;
  }
`;
