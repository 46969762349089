import React from 'react';
import GlobalStyle from './styles/global';
import { BrowserRouter } from 'react-router-dom';
import 'react-toastify/dist/ReactToastify.css';
import 'bootstrap/dist/css/bootstrap.min.css';

import Routes from './routes';

import { Container } from './styles';
import { QueryClientProvider, QueryClient } from 'react-query';

const queryClient = new QueryClient();

const App: React.FC = () => (
  <BrowserRouter>
    <Container>
      <QueryClientProvider client={queryClient}>
        <Routes />
      </QueryClientProvider>
    </Container>
    <GlobalStyle />
  </BrowserRouter>
);

export default App;
