import styled from 'styled-components';
import { darken } from 'polished';

export const Content = styled.div`
  padding-top: 1rem;
  padding-bottom: 1rem;
  width: 98%;
  margin: 0 auto;
`;

export const Table = styled.table`
  min-width: 650px;
  overflow-x: scroll;
  border-collapse: collapse;
  width: 100%;
  tr {
  }
  th,
  td {
    font-family: 'Red Hat text', sans-serif;
    color: #636e72;
    margin: 0;
    border: 1px solid #dfe6e9;
    font-weight: 100;
  }
`;

export const THead = styled.thead`
  background-color: #dfe6e9;
  width: 100%;
  height: 6.5rem;
  text-align: center;
  th {
    :first-child {
      border-radius: 0.5rem 0 0 0;
      border: none;
    }
    :last-child {
      border-radius: 0 0.5rem 0 0;
      border: none;
    }
    font-size: 13px;
    min-width: 6rem;
  }
`;

export const TBody = styled.tbody`
  width: 100%;
  background: rgba(255, 255, 255, 0.36);
  td {
    font-size: 1.4rem;
    height: 6rem;
    font-weight: 800;
    div {
      display: flex;
      flex-direction: row;
      align-items: center;
    }
    span.ranking {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 2.5rem;
      height: 2.5rem;
      border-radius: 50%;
    }
    span.rank1 {
      background: #f8b22a;
      color: #fff;
    }
    span.rank2 {
      background: #b0bcc1;
      color: #fff;
    }
    span.rank3 {
      background: #cbaa78;
      color: #fff;
    }
    span.profile {
      display: flex;
      width: 2.5rem;
      height: 2.5rem;
      border-radius: 50%;
      background-color: #2d3436;
      position: relative;
      margin-left: 1rem;
      margin-right: 1rem;
      justify-content: flex-end;
      align-items: flex-end;
      span {
        width: 0.7rem;
        height: 0.7rem;
        border-radius: 50%;
        border: 1px solid #fff;
      }
    }
  }
`;

export const CloseButton = styled.button`
  padding: 1rem 1.5rem;
  border: 0;
  background-color: #c2c2c1;
  border-radius: 4px;
  margin-top: 10px;
  margin-bottom: 5px;
  margin-right: 1042px;
  &:hover {
    background-color: ${darken(0.1, '#c2c2c1')};
  }
`;

export const CloseButtonLabel = styled.label`
  font-size: 1.4rem;
  color: #fff;
  cursor: pointer;
`;
