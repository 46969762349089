import React from 'react';
import { ReactSVG } from 'react-svg';
import { useHistory } from 'react-router-dom';

import checkIcon from '../../../assets/check.svg';

import * as S from './styles';

const Success: React.FC = () => {
    const history = useHistory();

    return (
        <S.Container>
            <ReactSVG src={checkIcon} />

            <S.SuccessTitle>
                Contato Sem Score cadastrado com sucesso
            </S.SuccessTitle>

            <S.CloseButton onClick={() => { history.goBack() }}>
                Fechar
            </S.CloseButton>
        </S.Container>
    );
};

export default Success;