import styled from "styled-components";

export const SelectContainer = styled.div`  
  display: flex;
  flex-direction: row;
  align-items: center;
`;
export const Container = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
> div{
  display: flex;
  flex-direction: row;
  align-items: center;
  button{
    border-radius: 5px;
    padding: 0.5rem;
    margin-left: 0.5rem;
    display: flex;
    align-items: center;
    background: transparent;
    transition: 0.2s;
    &.cancel{
      color: #D50F30;
      border: 1px solid #D50F30;
      &:hover{
        background: #D50F30;
        color: #fff;
      }
    }
    &.save{
      color: #19CB9E;
      border: 1px solid #19CB9E;
      &:hover{
        background: #19CB9E;
        color: #fff;
      }
    }
  }
}
select {
  height: 4.5rem;
  width: 30rem;
  font-size: 1.3rem;
  color: #757574;
  font-weight: 400;
  border: 0;
  border-radius: 0.4rem;
  padding-left: 1rem;
  outline: none;
  background: #f3f3f3;
}
@media (max-width: 768px) {
  select {
    margin: 0.7rem 0;
    height: 4rem;
    width: auto;
    font-size: 1rem;
    border-radius: 0.4rem;
    padding-left: 0.5rem;
  }
}
`;