import styled from 'styled-components';

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 8rem;
`;

export const SuccessTitle = styled.h2`
    margin-top: 2rem;
    margin-bottom: 2rem;
    font-weight: 600;

    @media(max-width: 768px) {
        font-size: 1.2rem;
    }
`;

export const Information = styled.span`
    margin-top: 2rem;
    margin-bottom: 4rem;
    font-size: 1.4rem;

    @media(max-width: 768px) {
        font-size: 1.1rem;
    }
`;

export const CloseButton = styled.button`
    box-shadow: none;
    background: #d50f30;
    border: 0;
    border-radius: 4px;
    padding: 14px 30px;
    color: #fff;
    font-size: 13px;
    text-transform: capitalize;
`;