export const normalizeText = (text: string) => {
    if(text === '' || text === null || text === undefined) {
      return '';
    }
  
    return text.normalize("NFD").replace(/[\u0300-\u036f]/g, "").toLowerCase();
}

export const toUpperFirsLetterinText = (text: string) => {
  if(text === undefined || text === null || text === '') {
    return '';
  }

  return text
      .toLowerCase()
      .split(' ')
      .map(function(text) {
          return text[0].toUpperCase() + text.substr(1);
      })
      .join(' ');
   }

export const capitalizeFirstLetter = (text: string) => {
  if(text === undefined || text === null || text === '') {
    return '';
  }
  const capitalizedText = text.charAt(0).toUpperCase() + text.toLowerCase().slice(1);

  return capitalizedText;
}