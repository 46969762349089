import React from 'react';
import ReactSelect, { Props as SelectProps } from 'react-select';

interface Props extends SelectProps {
  placeholder: string;
  style?: any;
  options: Array<{
    value: string;
    label: string;
  }>;
}

const Select: React.FC<Props> = ({
  options,
  placeholder,
  style,
  colorTheme,
  ...rest
}) => {
  return (
    <ReactSelect
      {...rest}
      options={options}
      placeholder={placeholder}
      isSearchable
      formatGroupLabel={(data) => <span>{data.label}</span>}
      styles={{
        control: (base: any) => ({
          ...base,
          ...style,
        }),
        menu: (base: any) => ({ ...base, fontSize: '1.5rem' }),
      }}
      theme={(theme) => ({
        ...theme,
        colors: {
          ...theme.colors,
          primary: !colorTheme ? '#d51d30' : colorTheme,
        },
      })}
    />
  );
};

export default Select;
