import React,{ useState } from "react";
import { ReactSVG } from "react-svg";
import api from "services/emeserver/api";
import authToken from "services/emeserver/authToken";
import { DataEnterprise } from "utils/InterFaces";
import { Container, SelectContainer } from "./styles";

interface Props{  
  enterpriselist: DataEnterprise[];
  editIcon: string;
  enterpriseName: string;
  callId: number;
  setIsUpdateEnterprise: (param: boolean) => void;
}
export const SelectEnterprise = (props: Props) => {
  const [selectOptionEnterprise, setSelecOptionEnterprise] = useState('');
  const [isEditingEnterprise, setIsEditingEnterprise] = useState(false);
  const [enterprise, setEnterprise] = useState(() => {
    const findEnterprise = props.enterpriselist.find(item => item.title === props.enterpriseName)
    return findEnterprise;
  })
  
  const handleEditEnterprise = () => {
    enterprise && setSelecOptionEnterprise(enterprise.slug);
    setIsEditingEnterprise(true);
  }

  const handleSaveEnterprise = () => {
    const finded = props.enterpriselist.find(item => item.slug === selectOptionEnterprise)
    finded && saveEnterprise(props.callId, selectOptionEnterprise, finded);
    setIsEditingEnterprise(false);
    props.setIsUpdateEnterprise(true);
  }

  const saveEnterprise = async (id: number, slug: string, enterprise: any) => {
    await api.put('call/update/enterprise',{
      call_id: id,
      enterprise_slug: slug 
    },{
      headers: {
        Authorization: authToken.token
      }
    });
    setEnterprise(enterprise);
  }

  return(
    <SelectContainer>
      {
        isEditingEnterprise ? (
        <Container>
          <select 
            value={selectOptionEnterprise} 
            onChange={(e) => setSelecOptionEnterprise(e.target.value)}
          >
            {
              props.enterpriselist.map(enterprise => (
                <option value={enterprise.slug} key={enterprise.slug} >{enterprise.title}</option>
              ))
            }
          </select>
          <div>
            <button onClick={handleSaveEnterprise} className="save">Salvar</button>
            <button onClick={() => setIsEditingEnterprise(false)} className="cancel">Cancelar</button>
          </div>
        </Container>
        ):(
            <h3>{enterprise?.title}
            <button
              onClick={handleEditEnterprise}
              style={{ background: "none", border: "0" }}
            >
              <ReactSVG

                src={props.editIcon}
                style={{ width: '22px', marginLeft: '4px', cursor: "pointer" }}
              />
            </button>
          </h3>               
        )
      }
    </SelectContainer>
  )
}