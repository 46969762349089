import styled from 'styled-components';

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;

    .filled {
        background-color: #D3D3D2;
    }
`;