import React, { FormEvent, useState } from 'react';
import { ReactSVG } from 'react-svg';
import { useHistory } from 'react-router-dom';
import { FiEye, FiEyeOff } from 'react-icons/fi';
import 'react-toastify/dist/ReactToastify.css';

import hmLogo from '../../assets/hm-logo.svg';

import api from '../../services/emeserver/api';

import authToken from '../../services/emeserver/authToken';

import * as S from './styles';
import { toast, ToastContainer } from 'react-toastify';

export default function Login(): JSX.Element {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [showPassword, setShowPassword] = useState(false);

  const history = useHistory();

  async function handleSubmit(e: FormEvent) {
    e.preventDefault();

    if (email.length <= 0) {
      return toast.error('O email precisa ser preenchido', {
        position: 'top-right',
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }

    if (password.length <= 0) {
      return toast.error('A senha precisa ser preenchida', {
        position: 'top-right',
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }

    const data = {
      email,
      password,
    };
    try {
      const response = await api.post('agents/login', data);

      if (response.data.statusCode === 400) {
        return toast.error(response.data.message, {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      }

      if (response.status === 200) {
        sessionStorage.setItem('token', response.data.token);
        sessionStorage.setItem('user', email);
        sessionStorage.setItem('permission', response.data.permission);
        sessionStorage.setItem('selectedAgent', JSON.stringify(response.data));
        sessionStorage.setItem(
          'available',
          JSON.stringify(response.data.available),
        );

        if (response.data.permission === 'admin-facebook') {
          return history.push('/facebook/forms/home');
        } else {
          return (window.location.href = '/home');
        }
      }
    } catch (error) {
      if (error.response.status === 500) {
        return toast.error(error.response.data.message, {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      }
    }
  }

  async function forgotPassword(e: FormEvent) {
    e.preventDefault();

    if (email.length <= 0) {
      return toast.error('Informe o email por favor', {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }

    const data = {
      to: email,
    };

    const response = await api.post('agents/forgotpassword', data, {
      headers: {
        Authorization: authToken.token,
      },
    });

    if (response.status === 200) {
      sessionStorage.setItem('code', response.data.code);
      sessionStorage.setItem('email', email);
      history.push('/forgotpassword');
    }
  }
  return (
    <S.Container>
      <S.Card>
        <S.Form>
          <S.LogoContainer>
            <ReactSVG src={hmLogo} />
            <h1>
              Dashboard <span>Leads</span>
            </h1>
          </S.LogoContainer>

          <S.InputsContainer>
            <S.InputContainer>
              <input
                style={{ width: '100%' }}
                type="email"
                placeholder="E-mail"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
            </S.InputContainer>

            <S.InputContainer style={{ marginTop: '30px' }}>
              <input
                placeholder="Senha"
                value={password}
                type={showPassword ? 'initial' : 'password'}
                onChange={(e) => setPassword(e.target.value)}
              />
              {showPassword ? (
                <S.IconContainer>
                  <FiEye
                    onClick={(_e) => setShowPassword(!showPassword)}
                    size={20}
                  />
                </S.IconContainer>
              ) : (
                <S.IconContainer>
                  <FiEyeOff
                    onClick={(_e) => setShowPassword(!showPassword)}
                    size={20}
                  />
                </S.IconContainer>
              )}
            </S.InputContainer>
          </S.InputsContainer>

          <S.ButtonContainer style={{ width: '50%' }} onClick={handleSubmit}>
            <span>Entrar</span>
          </S.ButtonContainer>

          <S.ForgotButtonContainer onClick={forgotPassword}>
            <span>Esqueci minha senha</span>
          </S.ForgotButtonContainer>
        </S.Form>
      </S.Card>
      <ToastContainer />
    </S.Container>
  );
}
