import styled from 'styled-components';
import { darken, lighten } from 'polished';

import iconArrow from '../../assets/arrow-down.svg';

export const Container = styled.div`
  display: flex;
  width: 100vw;
  align-items: center;
  justify-content: center;

  .Toastify__toast {
    font-size: 1.4rem;
  }
`;

export const Card = styled.div`
  padding: 8rem;
  width: 52vw;
  background-color: #fff;
  border-radius: 0.4rem;
  display: flex;
  flex-direction: column;
  align-items: center;

  @media(max-width: 768px) {
    width: 53vw;
    padding: 2rem;
  }
`;

export const Form = styled.div`
  width: 50%;
  height: 100%;
  display: block;

  @media(max-width: 1280px) {
    width: 75%;
  }

  @media(max-width: 768px) {
    width: 100%;
  }

  .fieldStyle {
        width: 100%;
        border: 1px solid #D3D3D2;
        padding: 20px;
        border-radius: 5px;
        font-size: 13px;
        background: #fff;
        margin-bottom: 20px;
        transition: border ease-in-out .3s;

        &:focus,
        &:active {
            outline: none;
            border-color: #D50F30;
        }
    }

    select.fieldStyle {
        background: url(${iconArrow}) no-repeat center right 20px, #fff;
        appearance: none;
    }

    textarea.fieldStyle {
        padding: 25px 30px;
    }

    .MuiStepIcon-root {
        width: 30px;
        height: 30px;
        fill: #fff;

        &.MuiStepIcon-completed {
            fill: #000;
        }

        .MuiStepIcon-text {
            fill: #757574;
            font-size: 10px;
            font-family: inherit;
        }

        &.MuiStepIcon-active {
            fill: #d50f30;
            width: 36px;
            height: 36px;
            margin-top: -3px;

            circle {
                fill: #d50f30 !important;
            }

            .MuiStepIcon-text {
                fill: #fff;
                font-weight: 700;
            }
        }
    }

    .MuiStepper-root {
        background: transparent;
        padding: 10px 0px 35px 0px;
    }

    .MuiStepConnector-alternativeLabel {
        top: 50%;
    }

    .MuiStepConnector-lineHorizontal {
        border-top-style: dotted;
        border-top-width: 2px;
        border-color: #D3D3D2;
    }

    .title-form {
        font-weight: 700;
        margin-top: 15px;
        color: #757574;
    }

    form {
        .title-1 {
            font-size: 16px;
            font-weight: 700;
            color: #1D1D1B;
            text-align: center;
        }

        .buttons-next {
            display: flex;
            width: 100%;
            margin-top: 30px;
            justify-content: center;

            .MuiButton-containedPrimary {
                margin: 0 5px;
                box-shadow: none;
                background: #000;
                padding: 14px 30px;
                color: #fff;
                font-size: 13px;
                text-transform: capitalize;
            }

            button {
                font-weight: 400;

                &:first-child {
                    background: #fff;
                    box-shadow: 0;
                    color: #757574;

                    &:hover {
                      background: ${darken(0.1, "#fff")};
                    }
                }

                &.black {
                    background: #000;
                    color: #fff;

                    &:hover {
                      background: ${lighten(0.4, "#000")};
                    }
                }

                &.red {
                    background: #d50f30;

                    &:hover {
                      background: ${darken(0.1, "#D50F30")};
                    }
                }
            }

            @media(max-width: 768px) {
              span { 
                font-size: 1rem;
              }
            }
        }
    }
`;

export const HeaderContainer = styled.header`
  background: #fff;
  padding: 2rem 4.5rem;
  height: 16rem;
  width: 85%;

  display: flex;
  justify-content: space-between;
  align-items: center;

  @media (max-width: 768px) {
    display: block;
    height: auto;
    padding: 2rem 0.5rem;
  }
`;

export const LogoContainer = styled.div`
  display: flex;
  margin-bottom: 2rem;

  h1 {
    margin: 0;
    padding: 0;
    font-weight: 300;
    font-size: 3.6rem;
    padding-top: 1.3rem;
    margin-left: 2rem;

    span {
      font-weight: 800;
      font-size: 3.6rem;
    }
  }

  @media (max-width: 768px) {
    justify-content: center;

    h1 {
      margin-left: 1rem;
      font-size: 1.4rem;

      span {
        font-size: 1.4rem;
      }
    }
  }
`;