import styled, { css } from 'styled-components';
import Modal from 'react-bootstrap/Modal';

interface CallTypeProps {
  isTypeSelected: boolean;
}

export const ModalContainer = styled(Modal)`
  .modal-custom {
    width: 100vw;
    max-width: 100vw;
    margin: 0;
    .modal-content {
      overflow: hidden;
      border-radius: 0;
      height: 100vh;
      margin: 0;
      padding: 0;
      border: 0;

      .modal-body {
        padding: 0;
        display: flex;

        .border-shadow {
          height: auto;
          background: #fff;
          box-shadow: 1px 2px 12px 5px rgb(0 0 0 / 8%);
          padding: 0;
        }

        .body-information {
          padding: 7rem 0px 4rem 1.5rem;
          background: #fff;
        }
      }
    }
  }

  @media (max-width: 1024px) {
    .modal-custom {
      .modal-content {
        height: auto;

        .modal-body {
          flex-direction: column;

          .border-shadow {
            height: auto;
            background: #fff;
            box-shadow: 1px 2px 12px 5px rgb(0 0 0 / 8%);
            padding: 0;
          }

          .body-information {
            padding: 7rem 0px 4rem 1.5rem;
            background: #fff;
          }
        }
      }
    }
  }
`;

export const CloseButtonModal = styled.div`
  font-size: 1.4rem;
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;

  .button {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 122px;
    height: 50px;
    background-color: #f3f3f3;
  }

  .button:hover {
    cursor: pointer;
    opacity: 0.6;
  }

  .close-icon {
  }

  .button span {
    margin-left: 0.5rem;
  }

  @media (max-width: 1024px) {
    margin-top: -2rem;
  }

  @media (max-width: 768px) {
    margin-top: -1.5rem;
    font-size: 1rem;

    .button span {
      font-size: 1rem;
    }
  }

  @media (max-width: 575px) {
    margin-top: 1rem;
    margin-right: 1rem;

    .close-icon {
      color: #757574;
    }

    span {
      color: #757574;
    }
  }
`;

export const FullColumn = styled.div`
  width: calc((10/ 10) * 100vw);
  height: 70vh;
  padding-left: 4rem;
  display: flex;
  flex-direction: column;
  overflow: overlay;

  span {
    padding-top: 1rem;
    font-weight: 600;
    font-size: 1.6rem;
  }

  @media (max-width: 1024px) {
    width: auto;
    height: auto;
  }

  @media (max-width: 768px) {
    padding-left: 1rem;
    padding-right: 1rem;

    span {
      padding-top: 2rem;
      font-size: 1.2rem;
    }
  }
`;

export const MediumColumn = styled.div`
  height: calc((5 / 10) * 70vh);
  padding-right: 4rem;
  display: flex;
  flex-direction: column;

  span {
    padding-top: 2rem;
    margin-bottom: -0.5rem;
    font-weight: 600;
    font-size: 1.6rem;
  }

  @media(max-width: 1600px) {
    margin-bottom: 2rem;
  }

  @media (max-width: 1024px) {
    width: auto;
    height: auto;
  }

  @media (max-width: 768px) {
    padding-left: 1rem;
    padding-right: 1rem;

    span {
      padding-top: 2rem;
      font-size: 1.2rem;
    }
  }

  @media (max-width: 1024px) {
    height: calc((2 / 4) * 100vh);
  }

  @media (max-width: 768px) {
    margin-bottom: 2rem;
    padding-right: 1rem;
    padding-left: 1rem;
    padding-top: 2rem;

    span {
      font-size: 1.2rem;
    }
  }
`;

export const SmallColumn = styled.div`
  height: calc((2 / 10) * 70vh);
  padding-right: 4rem;
  display: flex;
  flex-direction: column;
  
  span {
    font-weight: 600;
    font-size: 1.6rem;
    padding-top: 2rem;
  }

  @media(max-width: 1600px) {
    margin-bottom: 1rem;
  }

  @media (max-width: 1024px) {
    width: auto;
    height: auto;
  }

  @media (max-width: 768px) {
    padding-left: 1rem;
    padding-right: 1rem;

    span {
      padding-top: 2rem;
      font-size: 1.2rem;
    }
  }
  .responsive-calendar {
    margin-top: 2rem;
    font-size: 9px !important;

    border: 0.5px solid #f3f3f3;
    border-radius: 4px 4px 0 0;

    .Calendar__monthArrow {
      background-image: url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIyNCIgaGVpZ2h0PSIyNCIgdmlld0JveD0iMCAwIDI0IDI0IiBmaWxsPSJub25lIiBzdHJva2U9IiNENUQ1RDQiIHN0cm9rZS13aWR0aD0iMiIgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIiBzdHJva2UtbGluZWpvaW49InJvdW5kIiBjbGFzcz0iZmVhdGhlciBmZWF0aGVyLWNoZXZyb24tdXAiPjxwb2x5bGluZSBwb2ludHM9IjE4IDE1IDEyIDkgNiAxNSI+PC9wb2x5bGluZT48L3N2Zz4=');
    }

    .Calendar__monthArrowWrapper.-right,
    .Calendar__monthArrowWrapper.-left {
      span {
        width: 1em;
        height: 1em;
      }
    }

    .Calendar__monthText {
      font-family: 'Montserrat';
      font-weight: 600;
    }

    .Calendar__yearText {
      font-family: 'Montserrat';
      font-weight: 600;
    }

    .Calendar__weekDays {
      margin-bottom: 0;
      padding: 0px 2em;
      padding-top: 10px;
      background-color: #f3f3f3;
      height: 30px;
      color: #1e1d1c;

      .Calendar__weekDay {
        cursor: default;
        text-decoration: none;
        font-weight: 600;
      }
    }

    .Calendar__day.-selected,
    .Calendar__day.-selectedStart,
    .Calendar__day.-selectedEnd {
      font-weight: 600;
    }

    .Calendar__day.-selectedBetween {
      font-weight: 600;
      color: #fff;
    }

    .Calendar__day.-weekend:not(.-selected):not(.-blank):not(.-selectedStart):not(.-selectedEnd):not(.-selectedBetween) {
      color: #d5d5d5;
    }

    .Calendar__day.-ltr {
      min-height: 2.6em;
      font-size: 1.45em;
    }

    .Calendar__section {
      padding: 0px 2em;
      padding-top: 0;
    }

    .Calendar__day {
      margin-bottom: 0;
    }

    .Calendar__header {
      padding: 1em 0.5em;
    }

    .Calendar__section.-hiddenNext {
      opacity: 0;
    }
  }

  @media (max-width: 1500px) {
    .responsive-calendar {
      font-size: 8px !important;
    }
  }

  @media (max-width: 1200px) {
    .responsive-calendar {
      font-size: 8px !important;
    }
  }

  @media (max-width: 1024px) {
    height: calc((2 / 4) * 100vh);
  }

  @media (max-width: 768px) {
    margin-bottom: 2rem;
    padding-right: 1rem;
    padding-left: 1rem;
    padding-top: 2rem;

    span {
      font-size: 1.2rem;
    }

    .responsive-calendar {
      font-size: 8px !important;
    }
  }
`;

export const CityFilter = styled.div`
  width: calc((1 / 3) * 100vw);
  height: 70vh;
  border-right: 1px solid #f3f3f3;
  padding-left: 4rem;
  display: flex;
  flex-direction: column;

  span {
    padding-top: 1rem;
    font-weight: 600;
    font-size: 1.6rem;
  }

  @media (max-width: 1024px) {
    width: auto;
    height: auto;
    border-right: none;
    border-bottom: 1px solid #f3f3f3;
  }

  @media (max-width: 768px) {
    padding-left: 1rem;
    padding-right: 1rem;

    span {
      padding-top: 2rem;
      font-size: 1.2rem;
    }
  }
`;

export const Options = styled.div`
  height: 70vh;

  div {
    display: flex;
    flex-direction: column;

    .same-line {
      margin-top: 1.5rem;
      display: flex;
      flex-direction: row;

      label {
        padding-left: 1.5rem;
        font-size: 1.3rem;
        color: #757574;
      }
    }

    .checkbox-round {
      width: 1.3em;
      height: 1.3em;
      background-color: white;
      border-radius: 50%;
      vertical-align: middle;
      border: 2px solid #ddd;
      -webkit-appearance: none;
      outline: none;
      cursor: pointer;
      box-shadow: 0px 0px 0px 1px #454545;
    }

    .checkbox-round:checked {
      background-color: #444444;
    }
  }

  @media (max-width: 768px) {
    div {
      .same-line {
        label {
          padding-left: 1rem;
          font-size: 1rem;
        }
      }
    }
  }
`;

export const EnterpriseAndSalaryContainer = styled.div`
  width: calc((1 / 3) * 70vw);
  height: 70vh;
  display: flex;
  flex-direction: column;

  @media (max-width: 1024px) {
    width: auto;
    height: auto;
    border-right: none;
    border-bottom: 1px solid #f3f3f3;
  }

  @media (max-width: 768px) {
    padding-left: 1rem;
    padding-right: 1rem;
  }
`;

export const EntepriseFilter = styled.div`
  width: calc((1 / 3) * 100vw);
  border-right: 1px solid #f3f3f3;
  height: 70vh;
  padding-left: 4rem;
  display: flex;
  flex-direction: column;
  margin-bottom: 30px;
  border: 2px solid red;

  span {
    padding-top: 1rem;
    font-weight: 600;
    font-size: 1.6rem;
  }

  @media (max-width: 1024px) {
    width: auto;
    height: auto;
    border-right: none;
    border-bottom: 1px solid #f3f3f3;
  }

  @media (max-width: 768px) {
    padding-left: 1rem;
    padding-right: 1rem;

    span {
      padding-top: 2rem;
      font-size: 1.2rem;
    }
  }

  small {
    text-align: center;
    margin-top: 5rem;
    color: #a8a8a7;
  }
`;

export const SearchButton = styled.div`
  display: flex;
  align-items: center;
  margin-right: 5rem;
  margin-top: 2.4rem;

  background-color: #f3f3f3;
  height: 100px;
  padding: 0 2rem;
  border-radius: 0.5rem;
  justify-content: space-between;

  input {
    background-color: #f3f3f3;
    border: 0;
    margin-right: 0.5rem;
    font-size: 1.2rem;
    width: 100%;
    height: 55px;

    ::placeholder {
      font-size: 1.2rem;
    }
  }

  .search-icon {
    color: #d5d5d4;
  }

  @media (max-width: 768px) {
    width: 100%;
    justify-content: space-between;
    margin-right: 0;
    font-size: 1rem;

    input {
      ::placeholder {
        font-size: 1rem;
      }
    }
  }
`;

export const FilterAgent = styled.div`
  height: calc((1 / 4) * 70vh);
  background-color: red;
  padding-right: 4rem;
  border-top: 1px solid #f3f3f3;
  display: flex;
  flex-direction: column;
  margin-bottom: 30px;

  span {
    font-weight: 600;
    font-size: 1.6rem;
  }

  @media (max-width: 1024px) {
    width: auto;
    height: auto;
    border-right: none;
    border-bottom: 1px solid #f3f3f3;
  }

  @media (max-width: 768px) {
    padding-left: 1rem;
    padding-right: 1rem;

    span {
      padding-top: 2rem;
      font-size: 1.2rem;
    }
  }
`;

export const SelectButton = styled.div`
  display: flex;
  align-items: center;
  margin-right: 5rem;
  margin-top: 2rem;

  background-color: #f3f3f3;
  height: 5rem;
  padding: 0 2rem;
  border-radius: 0.5rem;
  justify-content: space-between;

  select {
    background-color: #f3f3f3;
    border: 0;
    margin-right: 0.5rem;
    font-size: 1.2rem;
    width: 100%;

    ::placeholder {
      font-size: 1.2rem;
    }
  }

  @media (max-width: 768px) {
    width: 100%;
    justify-content: space-between;
    margin-right: 0;
    font-size: 1rem;

    select {
      ::placeholder {
        font-size: 1rem;
      }
    }
  }
`;

export const TypeAndDateContainer = styled.div`
  width: calc((1 / 3) * 100vw);
  height: 70vh;
  display: flex;
  flex-direction: column;

  @media (max-width: 1024px) {
    width: auto;
    height: auto;
    border-right: none;
    border-bottom: 1px solid #f3f3f3;
  }

  @media (max-width: 768px) {
    padding-left: 1rem;
    padding-right: 1rem;
  }
`;

export const CallTypeFilter = styled.div`
  height: calc((1 / 4) * 100vh);
  padding-left: 4rem;
  border-bottom: 1px solid #f3f3f3;
  display: flex;
  flex-direction: column;

  span {
    padding-top: 1rem;
    font-weight: 600;
    font-size: 1.6rem;
  }

  .type-buttons {
    display: flex;
    flex-direction: row;
    margin-top: 2rem;
  }

  @media (max-width: 1024px) {
    width: auto;
    height: auto;
    border-right: none;
    border-bottom: 1px solid #f3f3f3;

    .type-buttons {
      margin-bottom: 2rem;
    }
  }

  @media (max-width: 768px) {
    padding-left: 1rem;
    padding-right: 1rem;

    .type-buttons {
      flex-direction: column;
      align-items: flex-start;
    }

    span {
      padding-top: 2rem;
      font-size: 1.2rem;
    }
  }
`;

export const FilterSalaryRange = styled.div`
  height: calc((1 / 4) * 70vh);
  padding-left: 4rem;
  border-top: 1px solid #f3f3f3;
  display: flex;
  flex-direction: column;

  span {
    padding-top: 2rem;
    font-weight: 600;
    font-size: 1.6rem;
  }

  @media (max-width: 1024px) {
    width: auto;
    height: auto;
    border-right: none;
    border-bottom: 1px solid #f3f3f3;
  }

  @media (max-width: 768px) {
    padding-left: 1rem;
    padding-right: 1rem;

    span {
      padding-top: 2rem;
      font-size: 1.2rem;
    }
  }
`;

export const SalaryBox = styled.div`
  width: 100%;
  height: calc((1/3) * 70vh);
  overflow: auto;
  padding: 1.7rem 0.5rem;
`;

export const SalaryItem = styled.div``;

export const SalryInput = styled.input``;

export const CallTypeFinancingButton = styled.button<CallTypeProps>`
  background-color: #fff;
  height: 5.5rem;
  padding-left: 2rem;
  padding-right: 2rem;
  border: 1px solid #d3d3d2;
  border-radius: 0.4rem;
  color: #757574;

  span {
    font-size: 1.2rem;
    color: #757574;
    font-weight: 400;
  }

  ${(props) =>
    props.isTypeSelected
      ? css`
          background-color: rgba(70, 190, 229, 0.2);
          color: #0d7dc1;
          border: rgba(70, 190, 229, 0.2);

          span {
            color: #0d7dc1;
            font-weight: 600;
          }
        `
      : css`
          background-color: #fff;
          color: #757574;
          border: 1px solid #d3d3d2;
        `}

  @media(max-width: 768px) {
    width: 75%;

    span {
      font-size: 1rem;
    }
  }
`;

export const CallTypeVisitButton = styled.button<CallTypeProps>`
  background-color: #fff;
  height: 5.5rem;
  padding-left: 2rem;
  padding-right: 2rem;
  margin-left: 2rem;
  border: 1px solid #d3d3d2;
  border-radius: 0.4rem;
  color: #757574;

  span {
    font-size: 1.2rem;
    color: #757574;
    font-weight: 400;
  }

  ${(props) =>
    props.isTypeSelected
      ? css`
          background-color: #faba8d;
          color: #ff6800;
          border: #faba8d;

          span {
            color: #ff6800;
            font-weight: 600;
          }
        `
      : css`
          background-color: #fff;
          color: #757574;
          border: 1px solid #d3d3d2;
        `}

  @media(max-width: 768px) {
    margin-top: 1rem;
    margin-left: 0;
    width: 75%;

    span {
      font-size: 1rem;
    }
  }
`;

export const DateFilter = styled.div`
  padding-left: 4rem;
  height: calc((3 / 4) * 100vh);
  padding-top: 4rem;

  span {
    font-weight: 600;
    font-size: 1.6rem;
  }

  .responsive-calendar {
    margin-top: 2rem;
    font-size: 9px !important;

    border: 0.5px solid #f3f3f3;
    border-radius: 4px 4px 0 0;

    .Calendar__monthArrow {
      background-image: url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIyNCIgaGVpZ2h0PSIyNCIgdmlld0JveD0iMCAwIDI0IDI0IiBmaWxsPSJub25lIiBzdHJva2U9IiNENUQ1RDQiIHN0cm9rZS13aWR0aD0iMiIgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIiBzdHJva2UtbGluZWpvaW49InJvdW5kIiBjbGFzcz0iZmVhdGhlciBmZWF0aGVyLWNoZXZyb24tdXAiPjxwb2x5bGluZSBwb2ludHM9IjE4IDE1IDEyIDkgNiAxNSI+PC9wb2x5bGluZT48L3N2Zz4=');
    }

    .Calendar__monthArrowWrapper.-right,
    .Calendar__monthArrowWrapper.-left {
      span {
        width: 1em;
        height: 1em;
      }
    }

    .Calendar__monthText {
      font-family: 'Montserrat';
      font-weight: 600;
    }

    .Calendar__yearText {
      font-family: 'Montserrat';
      font-weight: 600;
    }

    .Calendar__weekDays {
      margin-bottom: 0;
      padding: 0px 2em;
      padding-top: 10px;
      background-color: #f3f3f3;
      height: 30px;
      color: #1e1d1c;

      .Calendar__weekDay {
        cursor: default;
        text-decoration: none;
        font-weight: 600;
      }
    }

    .Calendar__day.-selected,
    .Calendar__day.-selectedStart,
    .Calendar__day.-selectedEnd {
      font-weight: 600;
    }

    .Calendar__day.-selectedBetween {
      font-weight: 600;
      color: #fff;
    }

    .Calendar__day.-weekend:not(.-selected):not(.-blank):not(.-selectedStart):not(.-selectedEnd):not(.-selectedBetween) {
      color: #d5d5d5;
    }

    .Calendar__day.-ltr {
      min-height: 2.6em;
      font-size: 1.45em;
    }

    .Calendar__section {
      padding: 0px 2em;
      padding-top: 0;
    }

    .Calendar__day {
      margin-bottom: 0;
    }

    .Calendar__header {
      padding: 1em 0.5em;
    }

    .Calendar__section.-hiddenNext {
      opacity: 0;
    }
  }

  @media (max-width: 1500px) {
    .responsive-calendar {
      font-size: 8px !important;
    }
  }

  @media (max-width: 1200px) {
    .responsive-calendar {
      font-size: 8px !important;
    }
  }

  @media (max-width: 1024px) {
    height: calc((2 / 4) * 100vh);
  }

  @media (max-width: 768px) {
    margin-bottom: 2rem;
    padding-right: 1rem;
    padding-left: 1rem;
    padding-top: 2rem;

    span {
      font-size: 1.2rem;
    }

    .responsive-calendar {
      font-size: 8px !important;
    }
  }
`;

export const FilterAndClearContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 5px;

  @media (max-width: 768px) {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
`;

export const FilterButton = styled.button`
  width: 16rem;
  height: 5rem;
  border-radius: 0.5rem;
  background-color: #d50f30;
  margin-right: 10px;
  border: 0;

  span {
    font-size: 1.4rem;
    font-weight: 600;
    color: #fff;
  }

  :hover {
    opacity: 0.6;
  }

  @media (max-width: 1024px) {
    margin-bottom: 2rem;
  }

  @media (max-width: 768px) {
    margin-bottom: 0.5rem;
    width: 12rem;

    span {
      font-size: 1.1rem;
    }
  }
`;


export const TypeDateFilter = styled.div`
  width: 100%;
  padding-top: 1rem;
  display: flex;
  flex-direction: column;

  div{
    display: flex;
    flex-direction: row;
    width: 100%;
    align-items: center;

    :first-child{
      margin-bottom: 0.5rem;
    }

    input{
      margin-right: 1rem;
    }
  }
`;