import styled from 'styled-components';
import { darken } from 'polished';

export const Container = styled.div`
  width: 60%;
  padding-top: 1rem;
  padding-bottom: 1rem;
  background-color: #f2f2f2;
`;

export const Content = styled.div`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
`;

export const UserRanking = styled.div`
  color: ${(props) => props.theme.colors.white};
  padding: 10px;
  width: 30%;

  > text {
    font-size: 14px;
    color: #b0bcc1;
    margin-left: 15px;
  }

  @media (max-width: 600px) {
    width: 100%;
  }
`;

export const LeadsProdutos = styled.div`
  color: ${(props) => props.theme.colors.white};
  padding: 10px;
  width: 30%;

  > text {
    font-size: 14px;
    color: #b0bcc1;
    margin-left: 15px;
  }

  @media (max-width: 600px) {
    width: 100%;
  }
`;

export const MediaScore = styled.div`
  color: ${(props) => props.theme.colors.white};
  padding: 10px;
  width: 30%;

  > text {
    font-size: 14px;
    color: #b0bcc1;
    margin-left: 15px;
  }

  @media (max-width: 600px) {
    width: 100%;
  }
`;

export const Grafico = styled.div`
  color: ${(props) => props.theme.colors.white};

  > text {
    font-size: 14px;
    color: #b0bcc1;
    margin-left: 15px;
  }

  @media (max-width: 600px) {
  }
`;

export const BoxRanking = styled.div`
  color: ${(props) => props.theme.colors.white};
  background-color: #fff;
  padding: 10px;
  margin-top: 15px;
  border-radius: 8px;

  > p {
    font-size: 14px;
    color: #2d3436;
    width: 50%;
    margin-left: 15px;
  }

  @media (max-width: 600px) {
    width: 100%;
  }
`;

export const Ranking1 = styled.div`
  display: inline-flex;
  margin: 40px 20px;
  > p {
    font-size: 14px;
    color: #2d3436;
    width: 50%;
    margin-left: 15px;
  }
  @media (max-width: 600px) {
  }
`;

export const Ranking2 = styled.div`
  display: inline-flex;
  margin: 40px 20px;
  > p {
    font-size: 14px;
    color: #2d3436;
    width: 50%;
    margin-left: 15px;
  }
  @media (max-width: 600px) {
  }
`;

export const Ranking3 = styled.div`
  display: inline-flex;
  margin: 40px 20px;
  > p {
    font-size: 14px;
    color: #2d3436;
    width: 50%;
    margin-left: 15px;
  }
  @media (max-width: 600px) {
  }
`;

export const IconImg = styled.img`
  height: auto;
  width: auto;
  @media (max-width: 600px) {
  }
`;

export const Tag = styled.div`
  background-color: #dfe6e9;
  border-radius: 20px;
  font-size: 16px;
  font-weight: 700;
  color: #636e72;
  padding: 10px 20px;
  display: table;
  margin-left: 20px;

  @media (max-width: 600px) {
  }
`;

export const LineSeparator = styled.div`
  width: 100%;
  height: 1px;
  background-color: #dfe6e9;
`;

export const Title = styled.h2`
  display: flex;
  align-self: flex-start;
  margin-left: 2.2rem;
  margin-top: 1rem;
  font-weight: 700;
  @media (max-width: 768px) {
    align-self: center;
    font-size: 1.6rem;
  }
`;

export const Header = styled.head`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
`;

export const CloseButton = styled.button`
  padding: 1rem 1.5rem;
  border: 0;
  background-color: #c2c2c1;
  border-radius: 4px;
  margin-top: 10px;
  margin-right: 50px;
  &:hover {
    background-color: ${darken(0.1, '#c2c2c1')};
  }
`;

export const CloseButtonLabel = styled.label`
  font-size: 1.4rem;
  color: #fff;
  cursor: pointer;
`;
