import React, { useState } from 'react';

import * as S from './styles';

interface CallCommentaryProps {
    call_id: number;
    initialCommentary: string;
    created_at: string;
    is_new: boolean;
    callBackHandleSaveCommentary(call_id: number, commentary: string): void; 
}

const CallCommentary: React.FC<CallCommentaryProps> = (props) => {
    const [commentary, setCommentary] = useState(() => {
        const {
            initialCommentary
        } = props;

        if(initialCommentary !== null) {
            return initialCommentary;
        }

        return "";
    });

    const call_id = props.call_id;
    const created_at = props.created_at;
    const is_new = props.is_new;

    const handleChangeCommentary = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
        const value = e.target.value;

        setCommentary(value);        
    };

    const handleSaveCommentary = () => {
        props.callBackHandleSaveCommentary(call_id, commentary);
    }

    return (
        <S.Container is_new>
            {
                is_new ? (
                    <>
                        <S.NewCommentary 
                            value={commentary}
                            onChange={(e) => handleChangeCommentary(e)}
                        />
                        <S.ButtonSave 
                            disabled={commentary !== ''?false:true} 
                            onClick={handleSaveCommentary}
                        >
                            Salvar comentário
                        </S.ButtonSave>
                    </>
                ) : (
                    <>
                        <S.CreatedAt>
                            {created_at}
                        </S.CreatedAt>
                        
                        <S.Commentary>
                            {commentary}
                        </S.Commentary>
                    </>
                )
            }
            
        </S.Container>
    )
};

export default CallCommentary;