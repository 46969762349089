import styled, { css } from 'styled-components';
import { darken } from 'polished';

export const Container = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  .Toastify__toast {
    font-size: 1.4rem;
  }
`;

export const Card = styled.div`
  padding: 4rem;
  width: 100%;
  background-color: #fff;
  border-radius: 0.4rem;
  display: flex;
  flex-direction: column;
  align-items: center;

  .MuiFormControlLabel-label {
    font-size: 1.5rem;
  }

  @media (max-width: 768px) {
    width: 53vw;
  }
`;
interface Props {
  is_collapsed?: boolean;
  waiting?: boolean;
  errorRequest?: boolean;
}

export const TableContent = styled.div<Props>`
  width: 150px;
  height: 100%;
  margin-top: 1rem;
  padding: 0.5rem;
  .MuiPaper-elevation1 {
    box-shadow: 0px 1px 1px 0px rgb(0 0 0 / 7%), 0px 1px 3px 0px rgb(0 0 0 / 5%);
    overflow: scroll;
    max-height: 74vh;

    &::-webkit-scrollbar {
      width: 4px;
    }

    &::-webkit-scrollbar-track {
      background: #fff;
    }

    &::-webkit-scrollbar-thumb {
      background-color: #e0dfda;
      border-radius: 4px;
    }
  }
  thead th {
    font-weight: bold;
    text-align: end;
    padding-left: 0.5rem;
    &:first-child {
      text-align: start;
    }
  }
  thead th {
    font-size: 2rem;
  }
  tbody td {
    padding-left: 2px;
    padding-right: 2px;
    font-size: 1.8rem;
    position: relative;
    input {
      width: 100%;
      border: 1px solid #e0dfda;
      border-radius: 2px;
      padding-top: 0.3rem;
      padding-bottom: 0.3rem;
      font-size: 1.8rem;
    }
    select {
      width: 100%;
      border: 1px solid #e0dfda;
      border-radius: 2px;
      padding-top: 0.3rem;
      padding-bottom: 0.3rem;
      optgroup {
        font-size: 1.2rem;
        color: #e0dfda;
        margin-top: 0.5rem;
        margin-bottom: 0.5rem;
      }
      option {
        color: rgba(0, 0, 0, 0.87);
        font-size: 1.2rem;
      }
    }
    .btn-tooltip {
      &::after {
        content: attr(data-tooltip);
        background-color: rgba(0, 0, 0, 0.87);
        color: #fff;
        padding: 0.2rem 0.5rem 0.2rem 0.5rem;
        border-radius: 2px;
        font-size: 0.9rem;
        position: absolute;
        top: 0;
        left: -1rem;
        z-index: 102;
        visibility: hidden;
        transition: 0.5s;
      }
      &:hover::after {
        visibility: visible;
      }
    }
    button {
      background: transparent;
      border: none;
      cursor: pointer;
      @media (max-width: 768px) {
        svg {
          margin-left: 0.5rem;
          margin-right: 1rem;
        }
      }
      svg.spin {
        animation: spin 2s infinite;
        color: #0d7dc1;
      }

      ${(props) =>
        props.waiting &&
        css`
          &:disabled {
            cursor: not-allowed;
          }
        `}

      @keyframes spin {
        from {
          transform: rotate(0deg);
          transition: 1s;
        }
        to {
          transform: rotate(360deg);
        }
      }
    }
  }
`;

export const ButtonCloseCollapse = styled.div<Props>`
  z-index: 100;
  width: 3rem;
  height: 3rem;
  margin-left: 0.5rem;

  @media (max-width: 998px) {
  }

  button {
    border: none;
    cursor: pointer;
    width: 3rem;
    height: 3rem;
    background: #e0dfda;
    border-radius: 0 3px 0 3px;
    svg {
      font-size: 2.6rem;
      color: #bf000b;
    }
  }
`;

export const CloseButton = styled.button`
  padding: 1rem 1.5rem;
  border: 0;
  background-color: #c2c2c1;
  border-radius: 4px;
  margin-top: 10px;
  margin-bottom: 5px;
  margin-right: 1042px;
  &:hover {
    background-color: ${darken(0.1, '#c2c2c1')};
  }
`;

export const CloseButtonLabel = styled.label`
  font-size: 1.4rem;
  color: #fff;
  cursor: pointer;
`;
