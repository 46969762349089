import styled, { css } from 'styled-components';

interface ContainerProps {
    is_new: boolean;
};

interface Button{    
    is_disable?: boolean;
}

export const Container = styled.div<ContainerProps>`
    margin-top: 0.5rem;
    display: flex;
    flex-direction: column;
    border-bottom: 1px solid rgb(211,211,210);

    ${props => props.is_new && css`
        border: 0;
    `}

    width: 70%;
`;

export const Title = styled.small`
    font-size: 1.4rem;
    color: #757574;
    margin-bottom: 0.8rem;
    display: block;
`;

export const CreatedAt = styled.span`
    background: #f3f3f3;
    color: #454545;
    padding: 0.5rem;
    width: 13rem;
    border-radius: 4px;
`;

export const Commentary = styled.span`
    width: 100%;
    margin-top: 2rem;
`;

export const NewCommentary = styled.textarea`
    width: 70%;
    border: 1px solid rgb(211, 211, 210);
    padding: 1rem;
`;

export const ButtonSave = styled.button`
    padding: 0.5rem;
    border: 1px solid #d3d3d2;
    background: #f3f3f3;
    width: 70%;
    margin-top: 1rem;
    border-radius: 2px;
    display: flex;
    align-items: center;
    justify-content: center;
    &:disabled{
        cursor: not-allowed;
    }
`;